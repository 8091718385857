.Form {
  &-Row {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0; }
    &_col2 {
      @include mf(sm) {
        display: flex;
        justify-content: space-between; } } }
  &-Column {
    margin-bottom: 15px;
    @include mf(sm) {
      width: calc(50% - 35px);
      margin-bottom: 0; }
    &:last-child {
      margin-bottom: 0; } }
  &-Btn {
    width: 188px;
    height: 45px;
    margin: 0 auto;
    @include mf(sm) {
      width: 290px;
      height: 59px;
      font-size: 15px; } } }
