//@import "../../../node_modules/bootstrap/scss/bootstrap-grid"
@import "bourbon";
@import "vars";

@include font-face("Creata", "/font/CreataThin/CreataThin", 100, $file-formats: woff);
@include font-face("Creata", "/font/CreataLight/CreataLight", 300, $file-formats: woff);
@include font-face("Creata", "/font/CreataRegular/CreataRegular", 400, $file-formats: woff);
@include font-face("Creata", "/font/CreataMedium/CreataMedium", 500, $file-formats: woff);
@include font-face("Creata", "/font/CreataBold/CreataBold", 700, $file-formats: woff);
@include font-face("Creata", "/font/CreataBlack/CreataBlack", 900, $file-formats: woff);


* {
  outline: none; }

a, p, h1, sup, span {
  color: inherit;
  font-weight: inherit; }

sup {
  font-size: 60%; }

b {
  font-weight: 600; }

svg {
  fill: currentColor; }

body {
  margin: 0;
  padding: 0;
  font-family: $font-default;
  font-size: 12px;
  font-weight: 400;
  color: #000;
  @include mf(sx) {
    font-size: 14px; }
  @include mf(sl) {
    font-size: 16px; } }

.fonts-stage-1 body {
  font-family: $webfont-default;
  line-height: 1.5; }

.fonts-stage-2 body {
  font-family: $webfont-default;
  line-height: 1.5; }

.Container {
  width: 100%;
  max-width: 1240px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box; }


.fancybox-container {
  z-index: 9000; }

.fancybox-content {
  background-color: transparent;
  padding: 0; }

.ImThanks {
  padding: 20px; }

.Color_yellow {
  color: $main-color; }

.fancybox-close-small {
  width: 38px;
  height: 38px;
  margin-top: 15px;
  margin-right: 15px;
  background-color: $main-color;
  border-radius: 50%;
  @include mf(sm) {
    margin-top: 34px;
    margin-right: 32px; } }

.fancybox-slide--html {
  padding: 0; }
