.SpecGift {
  &-Body {
    padding-bottom: 90px;
    @include mf(sl) {
      padding-bottom: 80px; } }
  &-Card {} }

.SpecGiftCard {
  text-align: center;
  @include mf(sl) {
    position: relative;
    padding: 30px 0 35px 60px;
    background-image: url(/img/SpecGiftCard-Circle.png);
    background-repeat: no-repeat;
    background-position: center right;
    text-align: left;
    box-shadow: 0 0 49px rgba(0, 0, 0, 0.11); }
  @include mf(lg) {
    padding-left: 110px; }
  &-Heading {
    margin-bottom: 10px;
    @include mf(sl) {
      margin-bottom: 5px; } }
  &-Subheading {
    margin-bottom: 10px;
    font-size: 12px;
    @include mf(sl) {
      margin-bottom: 30px;
      font-size: 18px; }
    br {
      @include mf(sl) {
        display: none; } } }
  &-MinText {
    margin-bottom: 20px;
    font-size: 10px;
    color: #8A8A8A;
    @include mf(sl) {
      margin-bottom: 0;
      font-size: 13px; } }
  &-SpecImgWrap {
    width: 227px;
    height: 195px;
    margin: 0 auto;
    padding-top: 60px;
    background-color: rgba(218, 39, 32, 0.46);
    border-radius: 50%;
    @include mf(sl) {
      position: absolute;
      top: -35px;
      right: -50px;
      width: initial;
      height: initial;
      padding: 0;
      background-color: transparent; } }
  &-SpecImg {
    display: block;
    width: 196px;
    margin: 0 auto;
    @include mf(sl) {
      width: auto; } } }
