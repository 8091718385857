body {
  overflow-x: hidden; }

.DefmenuWrapper {
  width: 100%;
  position: relative;
  overflow: hidden; }

.DefmenuContainer {
  position: relative;
  right: 0;
  transition: all 0.25s ease-in-out; }

.Defmenu {
  width: 250px;
  height: 100%;
  background: #191918;
  position: fixed;
  top: 0;
  //right: -250px
  z-index: 9999;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  &:after {
    content: '';
    position: absolute;
    box-shadow: 0 0 10px #000;
    top: 0;
    bottom: 0;
    right: -.1rem;
    width: .1rem; } }

.Defmenu_open {
  .Defmenu {
    right: 0; }
  .DefmenuContainer {
 } }    //right: 250px

.DefmenuOverlay {
  display: none; }

.Defmenu_open .DefmenuOverlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fade 500ms; }

.DefmenuContent {
  height: 100%;
  background: #333232;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px; }
  &::-webkit-scrollbar-thumb {
    background-color: #656565;
    border-radius: 0;
    &:hover {
      background-color: rgb(196, 196, 196); } }
  &::-webkit-scrollbar-track {
    background-color: rgb(237, 237, 237); } }

.DefmenuNav {
  width: 100%;
  padding: 0px;
  list-style: none;
  background: #ffffff; }

.DefmenuNav-Link {
  padding: 22px 18px 23px 23px;
  font-size: 12px;
  font-weight: 600;
  color: #494747;
  border-top: 1px solid #e9eaec;
  position: relative;
  display: block;
  text-decoration: none;
  &:after {
    content: url("../img/DefmenuNav-LinkArrow.png");
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 19px; } }

.DefmenuBtn-Container_fixed {
  right: 0;
  transition: all 0.25s ease-in-out; }

.Defmenu_open .DefmenuBtn-Container_fixed {
  //right: 250px
  right: 0; }

.DefmenuBtn {
  width: 66px;
  height: 66px;
  padding: 0;
  background: $main-color;
  border: none;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all ease-in-out .4s;
  &-Icon {
    width: 22px;
    height: 16px;
    border-radius: 2px;
    position: relative; }
  &-Line {
    width: 22px;
    height: 2px;
    margin-bottom: 5px;
    background-color: #000;
    border-radius: 25%;
    transition: all ease-in-out .4s;
    display: block;
    position: static;
    &:last-child {
      margin-bottom: 0; } } }

//.DefmenuBtn_open
//  .DefmenuBtn-Line:nth-child(1)
//    transform: rotate(45deg) translate(3px,4px)
//  .DefmenuBtn-Line:nth-child(2)
//    display: none
//  .DefmenuBtn-Line:nth-child(3)
//    transform: rotate(-45deg) translate(0.5px, -2.5px)
