.ImCallback {
  width: 100vw;
  min-height: 100vh;
  padding: 100px 0 30px;
  background-color: #fff;
  @include mf(sm) {
    padding: 140px 0 40px; }
  &-Title {
    margin-bottom: 15px;
    font-size: 24px;
    text-align: center;
    @include mf(sm) {
      font-size: 32px; } }
  &-Subtitle {
    margin-bottom: 50px;
    font-size: 14px;
    text-align: center;
    @include mf(sm) {
      margin-bottom: 45px;
      font-size: 16px; } }
  &-Form {}
  &-Input {
    margin-bottom: 10px; }
  &-Btn {
    @include mf(sm) {
      font-size: 15px; } }
  &-ProcessingPersonalDataText {
    margin-top: 15px;
    @include mf(sm) {
      margin-top: 25px; } } }

.ImCallbackForm {
  &-FormBg {
    margin-bottom: 40px;
    padding: 20px 0;
    background-color: #FBFBFB;
    @include mf(sm) {
      margin-bottom: 60px;
      padding: 85px 0; } }
  .Container {
    position: relative; }
  & {}
  &-Leaf {
    position: absolute;
    &_left {
      display: none;
      width: 60px;
      left: 50%;
      bottom: -70px;
      transform: translateX(-50%) rotate(-45deg);
      @include mf(sm) {
        display: block;
        width: 80px;
        left: -10px;
        bottom: -140px;
        transform: none; } }
    &_right {
      display: none;
      width: 60px;
      top: -50px;
      right: -10px;
      @include mf(sm) {
        display: block;
        width: 102px;
        top: -150px; } } }
  &-Heading {
    margin-bottom: 15px;
    font-size: 16px;
    text-align: center;
    @include mf(sm) {
      text-align: left; } }
  &-ProductList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
    @include mf(sm) {
      display: block; }
    &-Item {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0; } }
    .Radio-Name {
      br {
        @include mf(sm) {
          display: none; } } } }
  &-Textarea {
    height: 118px; } }
