.HowOrder {
  background-color: #F6F2F1;
  @include mf(sl) {
    background-image: url(/img/HowOrder-Bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
  &-Body {
    padding-top: 30px;
    padding-bottom: 55px;
    @include mf(sl) {
      padding-top: 45px;
      padding-bottom: 85px; } }
  &-Heading {
    margin-bottom: 10px;
    text-align: center;
    @include mf(sl) {
      margin-bottom: 55px; } }
  &-List {} }

.HowOrderList {
  @include mf(sl) {
    display: flex;
    justify-content: space-between;
    max-width: 1050px;
    margin: 0 auto; }
  &-Item {
    margin-bottom: 40px;
    text-align: center;
    @include mf(sl) {
      width: 50%;
      max-width: 460px;
      text-align: left; }
    &:last-child {
      margin-bottom: 0; } }
  &-Heading {
    position: relative;
    margin-bottom: 5px;
    padding-top: 43px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    @include mf(sl) {
      margin-bottom: 25px;
      padding-top: 0;
      padding-left: 80px;
      font-size: 30px; }
    &:before {
      position: absolute;
      top: 0;
      left: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px;
      padding-top: 3px;
      font-size: 16px;
      font-weight: 700;
      color: #FFFFFF;
      background-color: $main-color;
      border-radius: 50%;
      transform: translateX(-50%);
      content: '01';
      @include mf(sl) {
        top: -13px;
        left: 0;
        width: 60px;
        height: 60px;
        padding-top: 0;
        font-size: 22px;
        transform: none; } } }
  &-Text {
    margin-bottom: 13px;
    @include mf(sl) {
      font-size: 22px;
      line-height: 1.3; }
    br {
      display: none;
      @include mf(sl) {
        display: block; } } }
  &-MinText {
    margin-bottom: 20px;
    font-size: 10px;
    color: #7E7E7E;
    @include mf(sl) {
      margin-bottom: 25px;
      font-size: 14px; } }
  &-Btn {
    width: 188px;
    height: 45px;
    margin: 0 auto;
    padding-bottom: 4px;
    @include mf(sm) {
      width: 255px;
      height: 50px;
      font-size: 15px; }
    @include mf(sl) {
      margin: 0; } } }

.HowOrderList-Item:nth-child(2) {
  .HowOrderList-Heading {
    &:before {
      content: '02'; } } }
