.Delivery {
  &-Body {
    padding-top: 55px;
    padding-bottom: 25px;
    @include mf(sl) {
      display: flex;
      padding-top: 80px;
      padding-bottom: 0; } }
  &-Left {
    margin-bottom: 35px;
    @include mf(sl) {
      margin-bottom: 0; } }
  &-Right {
    max-width: 445px;
    margin: 0 auto;
    @include mf(sl) {
      margin-right: 0;
      padding-top: 40px; } }
  &-Heading {
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    @include mf(sm) {
      font-size: 30px;
      text-align: left; } }
  &-List {}
  &-Partner {}
  &-Phone {
    display: block;
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    @include mf(sm) {
      display: inline-block;
      margin-bottom: 10px;
      font-size: 30px;
      text-align: left; } } }

.DeliveryList {
  &-Item {
    position: relative;
    margin-bottom: 15px;
    padding-left: 30px;
    @include mf(sm) {
      padding-left: 45px;
      font-size: 22px; }
    &:last-child {
      margin-bottom: 0; }
    &:before {
      content: '';
      width: 13px;
      height: 11px;
      background-image: url(/img/check.png);
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 2px;
      left: 0;
      @include mf(xs) {
        width: 18px;
        height: 15px; }
      @include mf(sm) {
        top: 10px; } } } }

.DeliveryPartner {
  display: flex;
  flex-wrap: wrap;
  &-Item {
    width: 50%;
    height: 89px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #D2D2D2;
    border-right: 1px solid #D2D2D2;
    display: flex;
    align-items: center;
    &:nth-child(1),
    &:nth-child(2) {
      border-top: none; }
    &:nth-child(2n) {
      justify-content: flex-end;
      border-right: none; } }
  &-Img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    @include mf(xs) {
      width: initial !important; } } }

.DeliveryPartner-Item {
  &:nth-child(1) {
    .DeliveryPartner-Img {
      width: 141px; } }
  &:nth-child(2) {
    @include mf(xs) {
      justify-content: center; }
    .DeliveryPartner-Img {
      width: 116px; } }
  &:nth-child(3) {
    .DeliveryPartner-Img {
      width: 120px; } }
  &:nth-child(4) {
    .DeliveryPartner-Img {
      width: 112px; } } }
