.Footer {
  background-image: url(/img/Footer-Bg.jpg);
  background-position: center center;
  &-Body {
    position: relative;
    padding-top: 35px;
    padding-bottom: 35px;
    color: #FFFFFF;
    @include mf(sl) {
      display: flex;
      justify-content: space-between;
      padding-top: 40px;
      padding-bottom: 70px; } }
  &-Left {
    margin-bottom: 30px;
    text-align: center;
    @include mf(sl) {
      margin-bottom: 0;
      text-align: left;
      order: 3; } }
  &-Center {
    margin-bottom: 30px;
    text-align: center;
    @include mf(sl) {
      margin-bottom: 0;
      text-align: left;
      order: 2; } }
  &-Right {
    margin-bottom: 17px;
    text-align: center;
    @include mf(sl) {
      margin-bottom: 0;
      text-align: left;
      order: 1; } }
  &-Phone {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 300;
    @include mf(sl) {
      font-size: 18px; }
    a {
      font-weight: 700;
      text-decoration: none; } }
  &-WorkText {
    margin-bottom: 30px;
    font-size: 13px;
    font-weight: 300;
    @include mf(sl) {
      margin-bottom: 20px;
      font-size: 18px; } }
  &-Social {
    max-width: 290px;
    margin: 0 auto;
    @include mf(sl) {
      max-width: 170px;
      margin: 0; } }
  &-LinkList {}
  &-Logo {
    width: 173px;
    margin-bottom: 15px; }
  &-Copyright {
    font-size: 10px;
    font-weight: 300;
    @include mf(sl) {
      padding-top: 17px;
      font-size: 13px;
      color: #b3b3b3;
      border-top: 1px solid #fff; }
    br {
      display: none;
      @include mf(sl) {
        display: block; } } }
  &-ArrowToTop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    margin: 0 auto;
    padding-bottom: 3px;
    background-color: $main-color;
    border-radius: 50%;
    cursor: pointer;
    @include mf(sm) {
      position: fixed;
      right: 10px;
      bottom: 10px;
      z-index: 1000; }
    @include mf(llg) {
      top: 70px;
      right: auto;
      left: calc(100% + 100px);
      bottom: auto; }
    &-Icon {
      width: 18px; } } }

.FooterSocial {
  display: flex;
  align-items: center;
  justify-content: space-around;
  &-Item {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    @include mf(sl) {
      width: 34px;
      height: 34px; }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; } }
  &-Icon {
    display: block; } }

.FooterLinkList {
  &-Item {}
  &-Link {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 300;
    @include mf(sl) {
      margin-bottom: 15px;
      font-size: 15px; } } }
