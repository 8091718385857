.ProcessingPersonalDataText {
  font-size: 12px;
  color: #ACACAC;
  text-align: center;
  @include mf(xs) {
    font-size: 14px; }
  &-Link {
    color: #2b2e83;
    text-decoration: underline;
    display: inline; } }
