.Header {
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2001;
  @include mf(sl) {
    padding-top: 40px;
    position: absolute;
    z-index: auto; }
  .Container {
    padding-right: 0;
    @include mf(sl) {
      padding-right: 15px; } }
  &-Body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mf(sl) {
      position: relative;
      z-index: 2; } }
  &-Logo {
    width: 110px;
    @include mf(xs) {
      width: 180px; }
    @include mf(sl) {
      width: 150px; }
    @include mf(lg) {
      width: auto; } }
  &-Nav {
    margin-left: auto;
    margin-right: 20px;
    @include mf(lg) {
      margin-right: 100px; } }
  &-Social {
    width: 95px;
    margin-left: auto;
    margin-right: 20px;
    @include mf(xs) {
      width: 170px; }
    @include mf(sm) {
      margin-right: 45px; }
    @include mf(sl) {
      margin-left: 0; } }
  &-Info {
    display: none;
    @include mf(sl) {
      display: block; } }
  &-DefmenuBtn {
    @include mf(sl) {
      display: none; } } }

.HeaderNav {
  display: none;
  @include mf(sl) {
    display: block; }
  &-Link {
    margin-right: 10px;
    padding-bottom: 7px;
    font-size: 14px;
    color: #000;
    text-decoration: none;
    position: relative;
    @include mf(lg) {
      margin-right: 25px;
      font-size: 17px; }
    &:last-child {
      margin-right: 0; }
    &:before {
      content: '';
      width: 100%;
      height: 2px;
      background-color: transparent;
      position: absolute;
      left: 0;
      bottom: 0; }
    &:hover {
      &:before {
        background-color: #bc2232; } } } }


.HeaderSocial {
  display: flex;
  justify-content: space-between;
  &-Item {
    text-decoration: none;
    @include mf(xs) {
      width: 35px;
      height: 35px;
      background-color: #ffffff;
      border-radius: 50%;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
      display: flex;
      align-items: center;
      justify-content: center; }
    &:last-child {
      margin-right: 0; } }
  &-Img {
    max-height: 15px;
    @include mf(xs) {
      max-height: none; } } }

.HeaderInfo {
  &-Phone {}
  &-Text {
    font-size: 14px;
    text-align: right;
    line-height: 1;
    display: block; } }

.HeaderPhone {
  display: none;
  @include mf(sl) {
    color: #000;
    text-decoration: none;
    display: block; }
  &-Number {
    display: block;
    @include mf(sm) {
      margin-bottom: 5px;
      font-size: 20px;
      font-weight: 700;
      white-space: nowrap; } } }

