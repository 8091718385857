.Intro {
  &-Body {
    padding-top: 75px;
    padding-bottom: 15px;
    position: relative;
    z-index: 1;
    @include mf(sm) {
      padding-top: 80px; }
    @include mf(sl) {
      padding-top: 120px;
      padding-bottom: 140px; } }
  &-Content {
    max-width: 230px;
    @include mf(xs) {
      max-width: 330px; }
    @include mf(sm) {
      max-width: 620px; } }
  &-Leaf_static {
    width: 30px;
    margin-left: 100px;
    @include mf(xs) {
      margin-left: 180px; }
    @include mf(sm) {
      width: auto;
      margin-left: 335px; }
    @include mf(sl) {
      width: 80px;
      margin-bottom: 0px; } }
  &-Heading {
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    @include mf(xs) {
      font-size: 24px; }
    @include mf(sm) {
      margin-bottom: 35px;
      font-size: 46px;
      line-height: 1.4; }
    br {
      &.phone {
        @include mf(xs) {
          display: none; } }
      &.desktop {
        display: none;
        @include mf(xs) {
          display: block; } } } }
  &-Info {
    margin-bottom: 20px;
    @include mf(xs) {
      font-size: 14px; }
    @include mf(sm) {
      margin-bottom: 30px;
      font-size: 18px; }
    span {
      font-weight: 600; } }
  &-List {
    margin-bottom: 26px;
    @include mf(sm) {
      margin-bottom: 35px; } }
  &-Btn {
    width: 185px;
    height: 45px;
    padding-bottom: 3px;
    @include mf(sm) {
      width: 270px;
      height: 65px; } }
  &-Girl {
    width: 182px;
    position: absolute;
    top: 75px;
    left: 200px;
    @include mf(xs) {
      width: 210px;
      left: 300px; }
    @include mf(sm) {
      width: 300px;
      left: 550px; }
    @include mf(sl) {
      width: auto;
      left: auto;
      right: 0; }
    @include mf(lg) {
      top: 45px;
      right: 30px; } }
  &-Simg {
    display: none;
    @include mf(llg) {
      display: block;
      position: absolute; }
    &_left {
      @include mf(llg) {
        top: 330px;
        left: 0; } }
    &_right {
      @include mf(llg) {
        top: 0;
        right: 0; } } } }

.IntroList {
  &-Item {
    margin-bottom: 5px;
    padding-left: 20px;
    font-size: 11px;
    position: relative;
    @include mf(xs) {
      margin-bottom: 10px;
      padding-left: 30px;
      font-size: 14px; }
    @include mf(sm) {
      margin-bottom: 15px;
      padding-left: 45px;
      font-size: 18px; }
    &:last-child {
      margin-bottom: 0; }
    &:before {
      content: '';
      width: 13px;
      height: 11px;
      background-image: url(/img/check.png);
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 3px;
      left: 0;
      @include mf(xs) {
        width: 18px;
        height: 15px; }
      @include mf(sm) {
        width: 25px;
        height: 22px; } } } }
