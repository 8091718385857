.Radio {
  display: inline-block;
  &-Control {
    position: absolute;
    visibility: hidden; }
  &-Wrap {
    padding-left: 25px;
    position: relative;
    display: inline-block; }
  &-Dot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    background-color: #E9E9E9;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    left: 0;
    &:before {
      width: 9px;
      height: 9px;
      background-color: #fff;
      border-radius: 50%;
      content: ''; } }
  &-Name {
    font-size: 14px; } }


.Radio-Control:checked + .Radio-Wrap .Radio-Dot {
  border-color: #006899;
  &:before {
    display: block;
    background-color: $main-color; } }
