.SpecOffer {
  background: linear-gradient(27.07deg, #D37568 0%, #FFEB90 100%), #C4C4C4;
  &-Body {
    position: relative;
    padding-top: 70px;
    padding-bottom: 55px;
    @include mf(sl) {
      padding-top: 80px;
      padding-right: calc(100% - 550px);
      padding-bottom: 80px; } }
  &-New {
    position: absolute;
    top: 0;
    left: calc(50% - 72px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 47px;
    font-size: 13px;
    font-weight: 700;
    color: #FFFFFF;
    text-transform: uppercase;
    background: #DA2720;
    border-radius: 0px 0px 20px 20px;
    @include mf(sl) {
      left: auto;
      right: 0;
      width: 160px;
      height: 72px;
      font-size: 22px;
      text-transform: none; } }
  &-Heading {
    margin-bottom: 30px;
    color: #FFFFFF;
    text-align: center;
    @include mf(sl) {
      font-size: 36px;
      text-align: left; } }
  &-Simg {
    width: 100%;
    max-width: 310px;
    display: block;
    &_phone {
      position: relative;
      left: -15px;
      margin: 0 auto;
      @include mf(sl) {
        display: none; } }
    &_desktop {
      display: none;
      @include mf(sl) {
        display: block;
        position: absolute;
        top: 110px;
        right: 0;
        max-width: 400px; }
      @include mf(lg) {
        max-width: 582px; } } }
  &-Subheading {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    @include mf(sl) {
      margin-bottom: 30px;
      font-size: 18px;
      text-align: left; } }
  &-Info {
    margin-bottom: 30px;
    color: #ffffff;
    text-align: center;
    @include mf(sl) {
      position: relative;
      margin-bottom: 40px;
      padding-left: 40px;
      text-align: left; }
    &:before {
      @include mf(sl) {
        content: '';
        width: 25px;
        height: 23px;
        background-image: url(/img/check_white.png);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 2px;
        left: 0; } } }
  &-Btn {
    display: flex;
    width: 100%;
    max-width: 188px;
    height: 45px;
    margin: 0 auto;
    padding-bottom: 3px;
    font-size: 12px;
    background: #fff;
    color: #000;
    @include mf(sm) {
      max-width: 260px;
      height: 62px;
      font-size: 17px; } } }
