.NeedHelp {
  @include mf(sl) {
    background-image: url(/img/NeedHelp-Bg.png);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 50%;
    @include mf(lg) {
      background-size: initial; } }
  &-Body {
    padding-top: 35px;
    padding-bottom: 28px;
    @include mf(sl) {
      padding-top: 65px;
      padding-bottom: 80px; } }
  &-Content {
    max-width: 540px;
    margin: 0 auto;
    @include mf(sl) {
      margin: 0; } }
  &-Heading {
    margin-bottom: 10px;
    text-align: center;
    @include mf(sl) {
      text-align: left; } }
  &-Subheading {
    margin-bottom: 30px;
    font-size: 14px;
    text-align: center;
    @include mf(sl) {
      font-size: 18px;
      text-align: left; } }
  &-Form {} }

.NeedHelpForm {
  &-Personal {
    font-size: 10px;
    text-align: center;
    color: #878787;
    @include mf(sl) {
      font-size: 14px;
      text-align: left; } }
  .Form-Btn {
    font-weight: 600;
    @include mf(sl) {
      width: 255px;
      height: 50px;
      margin: 0;
      font-size: 15px; } }
  &-Textarea {
    height: 88px; }
  & {}
  & {} }
