.IProduct {
  width: 90%;
  height: 90%;
  max-width: 1200px;
  padding: 50px 20px;
  background-color: #fff;
  border-radius: 20px;
  @include mf(sm) {
    border-radius: 40px; }
  &-Body {
    padding: 17px;
    @include mf(sl) {
      display: flex;
      align-items: flex-start;
      height: 100%; } }
  &-Left {
    margin-bottom: 40px;
    @include mf(sl) {
      width: 360px; }
    .IProduct-Text {
      text-align: center;
      @include mf(sl) {
        text-align: left; } } }
  &-MHeading {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    text-align: center;
    @include mf(sl) {
      font-size: 28px;
      text-align: left; } }
  &-Right {
    @include mf(sl) {
      width: calc(100% - 360px);
      height: 100%;
      max-height: 600px;
      padding-left: 45px; } }
  &-Slider {
    width: 100%;
    max-width: 360px;
    margin: 0 auto 30px; }
  &-Content {
    //padding-right: 30px
    //+mf(sl)
    //  height: calc(100% - 125px)
    //  overflow-y: scroll
    //+mf(sl)
    //  &::-webkit-scrollbar
    //    width: 25px
    //  &::-webkit-scrollbar-track
    //    background-color: #d6d6d6
    //    border-radius: 20px
    //  &::-webkit-scrollbar-thumb
    //    background-color: #ededed
 }    //    border-radius: 20px
  &-Heading {
    margin-bottom: 20px;
    font-size: 16px;
    @include mf(sm) {
      font-size: 18px; } }
  &-Info {}
  &-Subheading {
    margin-bottom: 10px;
    font-size: 16px;
    @include mf(sm) {
      font-size: 18px; } }
  &-Text {
    margin-bottom: 20px;
    font-size: 12px;
    @include mf(sm) {
      font-size: 14px; }
    &:last-child {
      margin-bottom: 0; } }
  &-Btn {
    width: 100%;
    max-width: 290px;
    height: 49px;
    margin: 30px auto 40px;
    font-weight: 400;
    color: #000;
    background: #E7E7E7;
    @include mf(sm) {
      font-size: 16px; } }
  &-Price {
    margin-bottom: 5px;
    font-size: 22px;
    text-align: center;
    @include mf(sl) {
      text-align: left; } } }


.IProductSlider {
  &-Main {
    width: 100%;
    height: 305px;
    margin-bottom: 40px;
    &-Img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center; } }
  &-SlideWrap {
    display: flex;
    justify-content: space-between; }
  &-Slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90px;
    border: 1px solid #B8B8B8;
    cursor: pointer;
    &-Img {
      display: block;
      width: 90%;
      height: 90%;
      object-fit: contain;
      object-position: center center; } } }

.IProductSliderNav {
  width: calc(100% - 50px);
  margin: 0 auto;
  .slick-current {
    .IProductSlider-Slide {
      border-color: #d92321; } }
  .slick-slide {
    padding: 0 8px; }
  .Slider-Arrow_simple {
    top: calc(50% - 8px);
    width: 10px;
    height: 17px; }
  .Slider-Arrow_prev {
    left: -20px; }
  .Slider-Arrow_next {
    right: -20px; } }
