.BurgerMenu {
  width: 100%;
  height: 100vh;
  background: #fff;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 2002;
  overflow: hidden;
  transition: all ease-in-out 0.25s;
  &-Content {
    height: 100%;
    padding: 0 0 80px;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    &-In {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; } }
  &-Top {
    display: flex;
    justify-content: flex-end; }
  &-Close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    background-color: #FBBD00;
    cursor: pointer;
    &-Icon {
      font-size: 64px;
      &:before {
        color: #c2c2c2;
        transition: all linear 0.25s; }
      &:hover:before {
        color: #27323d; } } }
  &-LinksWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
  &-Links {
    position: relative;
    margin-bottom: 20px;
    font-size: 18px;
    letter-spacing: 0.3px;
    text-decoration: none;
    &:last-child {
      margin-bottom: 0; }
    &:hover, &.active {
      color: $main-color; } }
  &-Footer {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-Phone {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1px;
      color: #5d6c7a;
      text-decoration: none;
      display: inline-block; }
    &-Bot {
      margin-top: 5px;
      display: flex;
      align-items: center; } } }

.Defmenu_open .BurgerMenu {
  left: 0; }
