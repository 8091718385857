.Product {
  @include mf(llg) {
    position: relative; }
  &-Body {
    padding-top: 55px;
    padding-bottom: 50px;
    @include mf(lg) {
      position: relative;
      padding-top: 100px;
      padding-bottom: 160px; } }
  &-List {
    @include mf(lg) {
      position: relative;
      z-index: 2; } }
  &-Card {
    width: 100%;
    height: 100%; }
  &-Leaf {
    display: none;
    @include mf(llg) {
      position: absolute;
      display: block; }
    &_left {
      @include mf(llg) {
        top: 335px;
        left: 0; } }
    &_right {
      right: 0;
      bottom: 260px; } } }

.ProductList {
  @include mf(sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  &-Item {
    width: 100%;
    max-width: 380px;
    margin: 0 auto 50px;
    @include mf(sm) {
      max-width: 360px; }
    @include mf(sl) {
      width: calc(33.33% - 25px*2/3);
      max-width: none;
      margin: 0 0 50px; }
    @include mf(lg) {
      width: calc(33.33% - 25px*2/3);
      margin-bottom: 80px; }
    &:last-child {
      margin-bottom: 0; }
    &:nth-last-child(2) {
      @include mf(sl) {
        margin-bottom: 0; } }
    &_big {
      @include mf(sl) {
        width: calc(50% - 25px); } } } }

.ProductCard {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 16px;
  box-shadow: 8px 0 40px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  @include mf(sl) {
    border-radius: 40px; }
  &-Preview {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 240px;
    background-color: #FFF2D3;
    overflow: hidden; }
  &-PreviewImg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
    &_type {
      &_1 {
        width: 268px;
        object-position: center 20px; }
      &_2 {
        width: 239px;
        object-position: center 35px; }
      &_3 {
        width: 286px;
        object-position: center 45px; }
      &_4 {
        width: 258px;
        @include mf(sl) {
          width: 204px; } }
      &_5 {
        width: 138px;
        @include mf(sl) {
          width: 173px; } } } }
  &-Content {
    padding: 25px 10px 30px; }
  &-Heading {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    @include mf(sm) {
      font-size: 22px; } }
  &-Info {
    margin-top: 15px;
    text-align: center;
    @include mf(sm) {
      font-size: 16px; } }
  &-Btn {
    width: 188px;
    height: 45px;
    margin: 20px auto 0;
    padding-bottom: 3px;
    @include mf(sm) {
      width: 240px;
      height: 55px;
      font-size: 16px; } }
  &-Leaf {
    display: none;
    @include mf(lg) {
      display: block; }
    &_spec {
      @include mf(lg) {
        position: absolute;
        top: 85px;
        right: -225px; } } } }
.ProductCard_big {
  @include mf(sl) {
    display: flex; }
  @include mf(sl) {
    .ProductCard {
      &-Preview {
        width: 45%;
        height: 100%; }
      &-Content {
        width: 55%;
        padding: 40px 20px 60px 30px; }
      &-Heading {
        text-align: left; }
      &-Info {
        text-align: left; }
      &-Btn {
        width: 205px;
        height: 45px;
        margin: 20px 0 0;
        font-size: 14px; } } } }
