.Slider {
  width: 325px;
  height: 525px;
  margin: 0 auto;
  padding: 67px 62px 76px 70px;
  background-image: url(/img/SliderBg_iphone.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  left: -15px;
  @include mf(xs) {
    left: 0; }
  @include mf(lg) {
    width: 364px;
    height: 593px;
    padding: 75px 69px 84px 78px; }
  .slick-list {
    border-radius: 0 0 10px 10px;
    overflow: hidden; }
  &-Slide {
    width: 100%;
    height: 100%; }
  &-SlideImg {
    max-width: 100%;
    max-height: 100%;
    display: block;
    object-fit: contain; }
  &-Arrow {
    position: absolute;
    top: calc(50% - 19px);
    z-index: 100;
    width: 36px;
    height: 36px;
    transition: transform linear .20s;
    cursor: pointer;
    &_prev {
      left: 0;
      &:hover {
        transform: scale(1.2); } }
    &_next {
      right: 0;
      transform: rotate(180deg);
      &:hover {
        transform: scale(1.2) rotate(180deg); } }
    &-Icon {
      width: 100%; } } }
