.Input {
  display: flex;
  flex-direction: column;
  &-Control {
    height: 48px;
    padding-left: 30px;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid #ACACAC;
    border-radius: 56px; } }
