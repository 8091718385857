.Textarea {
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  padding-top: 10px;
  padding-left: 30px;
  background-color: transparent;
  font-size: 14px;
  border: 1px solid #ACACAC;
  border-radius: 31px;
  display: block;
  &::-webkit-scrollbar {
    width: .3em;
    background-color: #fff; }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); }
  &::-webkit-scrollbar-thumb {
    background-color: #3e3e3e; } }
