.DeliveryMap {
  @include mf(llg) {
    position: relative; }
  &-Body {
    padding-top: 45px;
    padding-bottom: 40px;
    @include mf(sl) {
      padding-top: 70px;
      padding-bottom: 65px; } }
  &-Heading {
    margin-bottom: 15px;
    text-align: center;
    @include mf(sm) {
      margin-bottom: 35px; } }
  &-Map {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    &_phone {
      @include mf(sx) {
        display: none; } }
    &_desktop {
      display: none;
      @include mf(sx) {
        display: block; } } }
  &-MinText {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
    @include mf(sm) {
      font-size: 18px; }
    &-Icon {
      width: 13px;
      height: 11px;
      margin-right: 17px;
      @include mf(sm) {
        width: 17px;
        height: 16px; } } }
  &-Leaf {
    display: none;
    @include mf(llg) {
      display: block; }
    &_left {
      @include mf(llg) {
        position: absolute;
        top: -80px;
        left: 0; } } } }
