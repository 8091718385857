.WeKnow {
  @include mf(lg) {
    position: relative; }
  &-Body {
    padding-top: 30px;
    padding-bottom: 40px; }
  &-Heading {
    margin-bottom: 25px;
    text-align: center;
    @include mf(sm) {
      margin-bottom: 30px;
      line-height: 1.2;
      text-align: left; }
    span {
      font-weight: 300; } }
  &-Subheading {
    margin-bottom: 50px;
    font-weight: 300;
    text-align: center;
    @include mf(sm) {
      font-size: 24px;
      font-weight: 400;
      text-align: left; }
    @include mf(sl) {
      margin-bottom: 30px; } }
  &-Content {
    @include mf(sm) {
      display: flex; } }
  &-Products {
    @include mf(sm) {
      width: 50%; }
    @include mf(sl) {
      padding-top: 40px; } }
  &-SimgWrap {
    max-width: 300px;
    margin: 35px auto 0;
    @include mf(sl) {
      position: relative;
      margin-top: 0; }
    @include mf(lg) {
      max-width: none; } }
  &-Simg {
    max-width: 100%;
    &-Info {
      margin-top: 28px;
      color: #737373;
      text-align: center;
      @include mf(sl) {
        position: absolute;
        bottom: 0;
        margin: 0;
        font-size: 14px;
        color: #000; } } }
  &-AddInfo {
    position: relative;
    margin-top: 28px;
    padding: 20px 34px 18px 71px;
    background: #FFFFFF;
    border-radius: 21px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.17);
    overflow: hidden;
    @include mf(sm) {
      margin-top: 55px; }
    @include mf(lg) {
      padding: 25px 25px 25px 110px;
      box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.07); }
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 100%;
      padding-bottom: 3px;
      font-size: 22px;
      font-weight: 900;
      color: #FFFFFF;
      background-color: $main-color;
      content: '+';
      @include mf(lg) {
        top: -3px;
        left: -10px;
        width: 80px;
        height: 80px;
        font-size: 31px;
        border-radius: 50%; } }
    p {} }
  &-Stamp {
    margin-top: 35px;
    @include mf(sm) {
      margin-top: 60px; } }
  &-Leaf {
    display: none;
    @include mf(llg) {
      position: absolute;
      display: block; }
    &_left {
      @include mf(llg) {
        left: 0;
        bottom: 510px; } }
    &_right {
      @include mf(llg) {
        top: 100px;
        right: 0; } } } }

.WeKnowProducts {
  &-Item {
    position: relative;
    margin-bottom: 30px;
    padding-left: 87px;
    @include mf(sm) {
      padding-left: 0; }
    @include mf(lg) {
      margin-bottom: 45px; }
    &:last-child {
      margin-bottom: 0; } }
  &-Icon {
    position: absolute;
    top: 10px;
    left: 0;
    @include mf(sm) {
      position: static;
      margin-left: 10px; } }
  &-Heading {
    font-size: 24px;
    font-weight: 300;
    @include mf(sm) {
      font-size: 40px; }
    @include mf(lg) {
      font-size: 62px; }
    b {
      color: $main-color;
      font-weight: 900; } }
  &-Text {
    margin-top: 5px;
    @include mf(sm) {
      margin-top: 15px;
      font-size: 18px; }
    br {
      display: none;
      @include mf(sl) {
        display: block; } } } }

.WeKnowStamp {
  display: flex;
  justify-content: space-between;
  &-Item {
    width: 50px;
    @include mf(sm) {
      width: auto; } }
  &-Img {
    width: 100%; } }
