.HighEfficiency {
  background-color: #f8f3f0;
  position: relative;
  @include mf(lg) {
    background-image: url(/img/HighEfficiency-Bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed; }
  &-Body {
    padding-top: 40px;
    padding-bottom: 100px;
    position: relative;
    z-index: 1;
    @include mf(sl) {
      padding-top: 45px;
      padding-bottom: 60px; } }
  &-Heading {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    @include mf(sl) {
      font-size: 32px; } }
  &-Subheading {
    font-size: 18px;
    text-align: center;
    line-height: 1.3;
    @include mf(sl) {
      font-size: 32px; } }
  &-List {
    margin-top: 25px;
    @include mf(sl) {
      margin-top: 40px; } }
  &-Leaf {
    position: absolute;
    &_left {
      left: 0;
      bottom: 0; }
    &_phone {
      @include mf(sl) {
        display: none; } } } }

.HighEfficiencyList {
  @include mf(sl) {
    display: flex;
    justify-content: space-between; }
  &-Item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 62px;
    max-width: 327px;
    margin: 0 auto 15px;
    background-image: url(/img/HighEfficiencyList-Item-Bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @include mf(sl) {
      width: 275px;
      height: 90px;
      background-image: url(/img/HighEfficiencyList-Item-Bg_desktop.png); }
    &:before {
      position: absolute;
      left: 50%;
      bottom: -24px;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      padding-top: 5px;
      font-size: 16px;
      font-weight: 900;
      line-height: 1;
      color: #FFFFFF;
      background-color: $main-color;
      border-radius: 50%;
      transform: translateX(-50%);
      content: '+';
      @include mf(sl) {
        left: 100%;
        bottom: 50%;
        transform: translate(-12px, 50%); }
      @include mf(lg) {
        width: 51px;
        height: 51px;
        padding-top: 0;
        padding-bottom: 3px;
        font-size: 20px; } }
    &:last-child {
      margin-bottom: 0;
      &:before {
        display: none; } } }
  &-Text {
    font-size: 16px;
    font-weight: 500; }
  &-Leaf {
    position: absolute;
    top: -15px;
    right: 0;
    width: 55px; } }
