.Phone {
  &-Only {
    display: inherit !important;
    @include mf(xs) {
      display: none !important; } }
  &-Hidden {
    display: none !important;
    @include mf(xs) {
      display: inherit !important; } } }

@import "../../common.blocks/Btn/index";
@import "../../common.blocks/Btn/_disable/index";

@import "../../common.blocks/Input/index";
@import "../../common.blocks/Input/_error/index";

@import "../../common.blocks/Textarea/index";

@import "../../common.blocks/Form/index";
@import "../../common.blocks/FormThx/index";

@import "../../common.blocks/ProcessingPersonalDataText/index";

@import "../../common.blocks/Logo/index";

@import "../../project.blocks/Defmenu/index";
@import "../../project.blocks/BurgerMenu/index";
@import "../../project.blocks/Btn/index";
@import "../../project.blocks/Heading/index";
@import "../../project.blocks/Slider/index";
@import "../../project.blocks/Input/index";
@import "../../project.blocks/Radio/index";
@import "../../project.blocks/IProduct/index";


