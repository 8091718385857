.Courses {
  @include mf(llg) {
    position: relative; }
  &-Body {
    padding-top: 45px;
    padding-bottom: 55px;
    @include mf(sl) {
      padding-top: 100px;
      padding-bottom: 165px;
      position: relative;
      z-index: 2; } }
  &-List {
    @include mf(sl) {
      position: relative;
      z-index: 2; } }
  &-Simg {
    display: none;
    @include mf(sl) {
      position: absolute;
      display: block; }
    &_bot {
      right: 50%;
      bottom: 0; }
    &_right {
      @include mf(sl) {
        left: 100%;
        bottom: 495px; } } }
  &-Leaf {
    display: none;
    &_left {
      @include mf(llg) {
        position: absolute;
        top: 120px;
        left: 0;
        display: block; } } } }

.CoursesList {
  @include mf(sm) {
    display: flex;
    justify-content: space-between; }
  &-Item {
    margin-bottom: 60px;
    @include mf(sm) {
      width: calc(33.33% - 10px); }
    @include mf(sl) {
      width: calc(33.33% - 30px*2/3); }
    &:last-child {
      margin-bottom: 0; } } }

.CoursesCard {
  width: 100%;
  max-width: 380px;
  padding-bottom: 35px;
  margin: 0 auto;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #C1C1C1;
  border-radius: 29px;
  @include mf(sl) {
    max-width: none;
    margin: 0; }
  &:before {
    position: absolute;
    top: 0;
    left: 50%;
    width: 24px;
    height: 26px;
    background-image: url(/img/CoursesCard-Hz.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: translate(-50%, -50%);
    content: ''; }
  &-Heading {
    padding: 30px 10px 20px;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    @include mf(xs) {
      padding-top: 35px;
      padding-bottom: 35px;
      font-size: 32px;
      line-height: 0.9; }
    br {
      display: none;
      @include mf(xs) {
        display: block; } } }
  &-Period {
    padding: 12px 10px 13px;
    font-size: 16px;
    text-align: center;
    background-color: #FFF5D9;
    @include mf(xs) {
      font-size: 21px; } }
  &-Preview {
    width: 100%;
    height: 214px;
    background-color: #fcf5eb;
    @include mf(xs) {
      height: 249px; } }
  &-PreviewImg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center; }
  &-Characteristic {
    padding: 35px 34px 30px 25px;
    @include mf(xs) {
      padding-left: 65px; } }
  &-Gift {
    padding: 11px 10px 10px; }
  &-Price {
    padding: 20px 10px 15px;
    @include mf(xs) {
      padding-bottom: 25px; } }
  &-Btn {
    width: 189px;
    height: 45px;
    margin: 0 auto;
    padding-bottom: 3px;
    @include mf(xs) {
      width: 100%;
      max-width: 255px;
      height: 50px;
      padding-bottom: 4px;
      font-size: 15px; } } }

.CoursesCardCharacteristic {
  &-Item {
    position: relative;
    margin-bottom: 15px;
    padding-left: 27px;
    @include mf(xs) {
      margin-bottom: 25px;
      font-size: 14px; }
    &:last-child {
      margin-bottom: 0; }
    &:before {
      position: absolute;
      top: 2px;
      left: 0;
      width: 13px;
      height: 11px;
      background-image: url(/img/check.png);
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      content: '';
      @include mf(xs) {
        width: 18px;
        height: 15px; } } } }

.CoursesCardGift {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF5D9;
  &-Text {
    line-height: 1.3;
    text-align: right;
    @include mf(xs) {
      font-size: 16px; } }
  &-Img {
    height: 50px;
    margin-left: 20px;
    @include mf(xs) {
      height: 80px; } } }

.CoursesCardPrice {
  display: flex;
  align-items: center;
  justify-content: center;
  &-Nums {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3px;
    @include mf(xs) {
      flex-direction: column;
      padding: 0; } }
  &-Old {
    position: relative;
    padding-top: 2px;
    font-size: 13px;
    font-weight: 700;
    color: #B0B0B0;
    @include mf(xs) {
      padding: 0;
      font-size: 17px; }
    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #DA2720;
      content: ''; } }
  &-New {
    margin-left: 15px;
    font-size: 18px;
    font-weight: 700;
    @include mf(xs) {
      margin-left: 0;
      padding: 0;
      font-size: 25px;
      line-height: 1; } }
  &-Discount {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    margin-left: 18px;
    padding-bottom: 2px;
    font-size: 10px;
    font-weight: 700;
    color: #FFFFFF;
    background-color: #DA2720;
    border-radius: 50%;
    @include mf(xs) {
      width: 42px;
      height: 42px;
      font-size: 13px; } } }

.CoursesCard_base {
  &:before {
    width: 24px;
    height: 26px; }
  .CoursesCard-Preview {
    height: 250px; } }

.CoursesCard_optimal {
  @include mf(sm) {
    border-color: #eb3332;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15); }
  @include mf(sl) {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15); }
  &:before {
    width: 33px;
    height: 36px; }
  .CoursesCard-Preview {
    height: 250px; } }

.CoursesCard_intense {
  &:before {
    width: 44px;
    height: 49px; }
  .CoursesCard-Preview {
    height: 250px; } }
