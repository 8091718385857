.ImThanks {
  display: none;
  //position: absolute
  //top: 0
  //left: 0
  //z-index: 9000
  width: 100vw;
  height: 100vh;
  padding: 100px 0;
  background-color: #fff;
  text-align: center;
  &-Leaf {
    width: 102px; }
  &-Title {
    margin-top: 30px;
    font-size: 32px;
    text-align: center; }
  &-Subtitle {
    margin-top: 15px;
    font-size: 14px;
    color: #444444;
    text-align: center; }
  &-SocialTitle {
    margin-top: 70px;
    font-size: 24px; }
  &-Social {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    &-Img {
      position: relative;
      top: 3px;
      margin-right: 10px; }
    .figcaption {
      font-size: 14px; } } }
