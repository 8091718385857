.HzDecor {
  &-Body {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  &-Line {
    width: calc(50% - 60px);
    height: 5px;
    background-image: url(/img/HzDecor-Line.png);
    background-repeat: repeat-x;
    @include mf(sl) {
      width: calc(50% - 245px); }
    &_left {}
    &_right {} }
  &-Img {
    width: 69px;
    @include mf(sl) {
      width: 160px; } } }
