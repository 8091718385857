.ForThose {
  &-Body {
    padding-top: 35px;
    @include mf(sm) {
      display: flex; } }
  &-Content {
    max-width: 292px;
    margin: 0 auto 55px;
    display: flex;
    align-items: flex-start;
    @include mf(sx) {
      max-width: 455px; }
    @include mf(sm) {
      width: 50%;
      max-width: none;
      padding-top: 40px; } }
  &-ImgText {
    width: 87px;
    @include mf(sm) {
      width: 105px;
      margin-top: 5px; }
    @include mf(sl) {
      width: auto; } }
  &-List {
    width: calc(100% - 87px);
    padding-left: 20px;
    @include mf(sl) {
      padding-left: 30px; }
    @include mf(lg) {
      padding-left: 55px; } }
  &-SliderWrap {
    @include mf(sm) {
      width: 50%; } }
  &-Heading {
    margin-bottom: 15px;
    text-align: center;
    @include mf(sm) {
      font-size: 32px; } }
  &-Slider {}
  &-Leaf {
    display: none;
    &_left {} } }

.ForThoseList {
  &-Item {
    margin-bottom: 13px;
    padding-left: 25px;
    position: relative;
    @include mf(sx) {
      margin-bottom: 30px; }
    @include mf(sl) {
      padding-left: 35px;
      font-size: 22px; }
    @include mf(lg) {
      margin-bottom: 35px;
      padding-left: 45px; }
    &:before {
      content: '';
      width: 13px;
      height: 11px;
      background-image: url(/img/check.png);
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 3px;
      left: 0;
      @include mf(xs) {
        width: 18px;
        height: 15px; }
      @include mf(sl) {
        top: 9px; } } } }


.ForThoseSlider {
  &-Slide {}
  &-SlideImg {} }
