.CourseTable {
  &-Body {
    padding-top: 50px;
    padding-bottom: 25px;
    @include mf(sl) {
      padding-top: 75px;
      padding-bottom: 50px; } }
  &-Heading {
    margin-bottom: 15px;
    text-align: center; }
  &-Subheading {
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    @include mf(sl) {
      margin-bottom: 55px;
      font-size: 22px; } }
  &-Table {
    width: 100%;
    margin: 0 auto;
    &_phone {
      display: block;
      max-width: 320px;
      @include mf(sl) {
        display: none; } }
    &_desktop {
      display: none;
      @include mf(sl) {
        display: block;
        max-width: 1196px; } } }
  &-MinText {
    margin-top: 20px;
    font-size: 10px;
    color: #6B6B6B;
    text-align: center;
    @include mf(sl) {
      margin-top: 40px;
      font-size: 14px; } } }
