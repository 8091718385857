.WhyWeSert {
  background-color: #F6F2F1;
  @include mf(lg) {
    background-image: url(/img/WhyWeSert-Bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed; }
  &-Body {
    padding-top: 45px;
    padding-bottom: 40px;
    @include mf(lg) {
      padding-top: 80px;
      padding-bottom: 100px; } }
  &-List {
    max-width: 243px;
    margin: 0 auto;
    @include mf(sx) {
      max-width: 300px; }
    @include mf(sm) {
      max-width: none; } } }

.WhyWeSertList {
  @include mf(sm) {
    display: flex;
    justify-content: space-between; }
  &-Item {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    @include mf(sm) {
      display: block;
      width: calc(33.33% - 10px);
      margin-bottom: 0; }
    @include mf(lg) {
      width: auto; }
    &:last-child {
      margin-bottom: 0; } }
  &-Label {
    padding-bottom: 5px;
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
    color: $main-color;
    @include mf(sm) {
      margin-bottom: 10px;
      padding-bottom: 0; }
    @include mf(lg) {
      margin-bottom: 20px;
      font-size: 52px;
      text-shadow: 0 0 2px #F6F2F1, 0 0 2px #F6F2F1, 0 0 2px #F6F2F1, 0 0 2px #F6F2F1; } }
  &-Text {
    margin-left: 15px;
    @include mf(sm) {
      margin-left: 0; }
    @include mf(lg) {
      font-size: 18px;
      text-shadow: 0 0 2px #F6F2F1, 0 0 2px #F6F2F1, 0 0 2px #F6F2F1, 0 0 2px #F6F2F1; } } }
