.Btn {
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
  background: linear-gradient(90deg, #DA2720 0%, #FBBD00 100%), #C4C4C4;
  border-radius: 65px;
  transition: all ease-in-out .25s;
  @include mf(sm) {
    font-size: 20px; }
  &:hover {
    box-shadow: 5px 5px 10px rgba(218, 39, 32, 0.2); } }
