@charset "UTF-8";
/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: https://meyerweb.com
	HTML5 Doctor				:: https://html5doctor.com
	and the HTML5 Boilerplate	:: https://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary {
  display: block; }

/* Handle box-sizing while better addressing child elements:
   https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/*!* Responsive images and other embedded objects
*!
img,
object,
embed {max-width: 100%;}*/
/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	 In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:

#map img {
		max-width: none;
}
*/
/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted.
	don't forget to restore the bullets within content. */
ul, ol {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */ }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: bottom; }

.ie7 input[type="checkbox"] {
  vertical-align: baseline; }

.ie6 input {
  vertical-align: text-bottom; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

small {
  font-size: 85%; }

strong {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights https://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0; }

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

@font-face {
  font-family: "Creata";
  font-style: normal;
  font-weight: 100;
  src: url("/font/CreataThin/CreataThin.woff") format("woff"); }

@font-face {
  font-family: "Creata";
  font-style: normal;
  font-weight: 300;
  src: url("/font/CreataLight/CreataLight.woff") format("woff"); }

@font-face {
  font-family: "Creata";
  font-style: normal;
  font-weight: 400;
  src: url("/font/CreataRegular/CreataRegular.woff") format("woff"); }

@font-face {
  font-family: "Creata";
  font-style: normal;
  font-weight: 500;
  src: url("/font/CreataMedium/CreataMedium.woff") format("woff"); }

@font-face {
  font-family: "Creata";
  font-style: normal;
  font-weight: 700;
  src: url("/font/CreataBold/CreataBold.woff") format("woff"); }

@font-face {
  font-family: "Creata";
  font-style: normal;
  font-weight: 900;
  src: url("/font/CreataBlack/CreataBlack.woff") format("woff"); }

* {
  outline: none; }

a, p, h1, sup, span {
  color: inherit;
  font-weight: inherit; }

sup {
  font-size: 60%; }

b {
  font-weight: 600; }

svg {
  fill: currentColor; }

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #000; }
  @media (min-width: 575px) {
    body {
      font-size: 14px; } }
  @media (min-width: 992px) {
    body {
      font-size: 16px; } }

.fonts-stage-1 body {
  font-family: "Creata", sans-serif;
  line-height: 1.5; }

.fonts-stage-2 body {
  font-family: "Creata", sans-serif;
  line-height: 1.5; }

.Container {
  width: 100%;
  max-width: 1240px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box; }

.fancybox-container {
  z-index: 9000; }

.fancybox-content {
  background-color: transparent;
  padding: 0; }

.ImThanks {
  padding: 20px; }

.Color_yellow {
  color: #FBBD00; }

.fancybox-close-small {
  width: 38px;
  height: 38px;
  margin-top: 15px;
  margin-right: 15px;
  background-color: #FBBD00;
  border-radius: 50%; }
  @media (min-width: 768px) {
    .fancybox-close-small {
      margin-top: 34px;
      margin-right: 32px; } }

.fancybox-slide--html {
  padding: 0; }

.Phone-Only {
  display: inherit !important; }
  @media (min-width: 480px) {
    .Phone-Only {
      display: none !important; } }

.Phone-Hidden {
  display: none !important; }
  @media (min-width: 480px) {
    .Phone-Hidden {
      display: inherit !important; } }

.Btn {
  font-size: 100%;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center; }

button.Btn {
  display: block;
  text-align: center; }

.Btn_disable {
  background-color: #C2C2C2 !important;
  font-size: 0;
  cursor: not-allowed; }
  .Btn_disable:before {
    content: 'Ждите...';
    font-size: 14px; }

.Input {
  display: flex;
  flex-direction: column; }
  .Input-Control {
    padding-left: 20px;
    background-color: #fff;
    font-size: 14px;
    border: none;
    border-radius: 5px; }

.Input_error {
  background-color: #fed1dd !important; }

.Textarea {
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  padding-top: 10px;
  padding-left: 30px;
  background-color: transparent;
  font-size: 14px;
  border: 1px solid #ACACAC;
  border-radius: 31px;
  display: block; }
  .Textarea::-webkit-scrollbar {
    width: .3em;
    background-color: #fff; }
  .Textarea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .Textarea::-webkit-scrollbar-thumb {
    background-color: #3e3e3e; }

.Form-Row {
  margin-bottom: 15px; }
  .Form-Row:last-child {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .Form-Row_col2 {
      display: flex;
      justify-content: space-between; } }

.Form-Column {
  margin-bottom: 15px; }
  @media (min-width: 768px) {
    .Form-Column {
      width: calc(50% - 35px);
      margin-bottom: 0; } }
  .Form-Column:last-child {
    margin-bottom: 0; }

.Form-Btn {
  width: 188px;
  height: 45px;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .Form-Btn {
      width: 290px;
      height: 59px;
      font-size: 15px; } }

.ProcessingPersonalDataText {
  font-size: 12px;
  color: #ACACAC;
  text-align: center; }
  @media (min-width: 480px) {
    .ProcessingPersonalDataText {
      font-size: 14px; } }
  .ProcessingPersonalDataText-Link {
    color: #2b2e83;
    text-decoration: underline;
    display: inline; }

.Logo-Image {
  max-width: 100%;
  display: block; }

body {
  overflow-x: hidden; }

.DefmenuWrapper {
  width: 100%;
  position: relative;
  overflow: hidden; }

.DefmenuContainer {
  position: relative;
  right: 0;
  transition: all 0.25s ease-in-out; }

.Defmenu {
  width: 250px;
  height: 100%;
  background: #191918;
  position: fixed;
  top: 0;
  z-index: 9999;
  overflow: hidden;
  transition: all 0.25s ease-in-out; }
  .Defmenu:after {
    content: '';
    position: absolute;
    box-shadow: 0 0 10px #000;
    top: 0;
    bottom: 0;
    right: -.1rem;
    width: .1rem; }

.Defmenu_open .Defmenu {
  right: 0; }

.DefmenuOverlay {
  display: none; }

.Defmenu_open .DefmenuOverlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fade 500ms; }

.DefmenuContent {
  height: 100%;
  background: #333232;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden; }
  .DefmenuContent::-webkit-scrollbar {
    width: 5px; }
  .DefmenuContent::-webkit-scrollbar-thumb {
    background-color: #656565;
    border-radius: 0; }
    .DefmenuContent::-webkit-scrollbar-thumb:hover {
      background-color: #c4c4c4; }
  .DefmenuContent::-webkit-scrollbar-track {
    background-color: #ededed; }

.DefmenuNav {
  width: 100%;
  padding: 0px;
  list-style: none;
  background: #ffffff; }

.DefmenuNav-Link {
  padding: 22px 18px 23px 23px;
  font-size: 12px;
  font-weight: 600;
  color: #494747;
  border-top: 1px solid #e9eaec;
  position: relative;
  display: block;
  text-decoration: none; }
  .DefmenuNav-Link:after {
    content: url("../img/DefmenuNav-LinkArrow.png");
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 19px; }

.DefmenuBtn-Container_fixed {
  right: 0;
  transition: all 0.25s ease-in-out; }

.Defmenu_open .DefmenuBtn-Container_fixed {
  right: 0; }

.DefmenuBtn {
  width: 66px;
  height: 66px;
  padding: 0;
  background: #FBBD00;
  border: none;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all ease-in-out .4s; }
  .DefmenuBtn-Icon {
    width: 22px;
    height: 16px;
    border-radius: 2px;
    position: relative; }
  .DefmenuBtn-Line {
    width: 22px;
    height: 2px;
    margin-bottom: 5px;
    background-color: #000;
    border-radius: 25%;
    transition: all ease-in-out .4s;
    display: block;
    position: static; }
    .DefmenuBtn-Line:last-child {
      margin-bottom: 0; }

.BurgerMenu {
  width: 100%;
  height: 100vh;
  background: #fff;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 2002;
  overflow: hidden;
  transition: all ease-in-out 0.25s; }
  .BurgerMenu-Content {
    height: 100%;
    padding: 0 0 80px;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden; }
    .BurgerMenu-Content-In {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
  .BurgerMenu-Top {
    display: flex;
    justify-content: flex-end; }
  .BurgerMenu-Close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    background-color: #FBBD00;
    cursor: pointer; }
    .BurgerMenu-Close-Icon {
      font-size: 64px; }
      .BurgerMenu-Close-Icon:before {
        color: #c2c2c2;
        transition: all linear 0.25s; }
      .BurgerMenu-Close-Icon:hover:before {
        color: #27323d; }
  .BurgerMenu-LinksWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
  .BurgerMenu-Links {
    position: relative;
    margin-bottom: 20px;
    font-size: 18px;
    letter-spacing: 0.3px;
    text-decoration: none; }
    .BurgerMenu-Links:last-child {
      margin-bottom: 0; }
    .BurgerMenu-Links:hover, .BurgerMenu-Links.active {
      color: #FBBD00; }
  .BurgerMenu-Footer {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .BurgerMenu-Footer-Phone {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1px;
      color: #5d6c7a;
      text-decoration: none;
      display: inline-block; }
    .BurgerMenu-Footer-Bot {
      margin-top: 5px;
      display: flex;
      align-items: center; }

.Defmenu_open .BurgerMenu {
  left: 0; }

.Btn {
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
  background: linear-gradient(90deg, #DA2720 0%, #FBBD00 100%), #C4C4C4;
  border-radius: 65px;
  transition: all ease-in-out .25s; }
  @media (min-width: 768px) {
    .Btn {
      font-size: 20px; } }
  .Btn:hover {
    box-shadow: 5px 5px 10px rgba(218, 39, 32, 0.2); }

.Heading {
  font-weight: 600;
  line-height: 1.4;
  color: #000; }
  .Heading_lvl2 {
    font-size: 20px; }
    @media (min-width: 768px) {
      .Heading_lvl2 {
        font-size: 46px; } }

.Slider {
  width: 325px;
  height: 525px;
  margin: 0 auto;
  padding: 67px 62px 76px 70px;
  background-image: url(/img/SliderBg_iphone.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  left: -15px; }
  @media (min-width: 480px) {
    .Slider {
      left: 0; } }
  @media (min-width: 1200px) {
    .Slider {
      width: 364px;
      height: 593px;
      padding: 75px 69px 84px 78px; } }
  .Slider .slick-list {
    border-radius: 0 0 10px 10px;
    overflow: hidden; }
  .Slider-Slide {
    width: 100%;
    height: 100%; }
  .Slider-SlideImg {
    max-width: 100%;
    max-height: 100%;
    display: block;
    object-fit: contain; }
  .Slider-Arrow {
    position: absolute;
    top: calc(50% - 19px);
    z-index: 100;
    width: 36px;
    height: 36px;
    transition: transform linear .20s;
    cursor: pointer; }
    .Slider-Arrow_prev {
      left: 0; }
      .Slider-Arrow_prev:hover {
        transform: scale(1.2); }
    .Slider-Arrow_next {
      right: 0;
      transform: rotate(180deg); }
      .Slider-Arrow_next:hover {
        transform: scale(1.2) rotate(180deg); }
    .Slider-Arrow-Icon {
      width: 100%; }

.Input {
  display: flex;
  flex-direction: column; }
  .Input-Control {
    height: 48px;
    padding-left: 30px;
    font-size: 14px;
    background-color: transparent;
    border: 1px solid #ACACAC;
    border-radius: 56px; }

.Radio {
  display: inline-block; }
  .Radio-Control {
    position: absolute;
    visibility: hidden; }
  .Radio-Wrap {
    padding-left: 25px;
    position: relative;
    display: inline-block; }
  .Radio-Dot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    background-color: #E9E9E9;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    left: 0; }
    .Radio-Dot:before {
      width: 9px;
      height: 9px;
      background-color: #fff;
      border-radius: 50%;
      content: ''; }
  .Radio-Name {
    font-size: 14px; }

.Radio-Control:checked + .Radio-Wrap .Radio-Dot {
  border-color: #006899; }
  .Radio-Control:checked + .Radio-Wrap .Radio-Dot:before {
    display: block;
    background-color: #FBBD00; }

.IProduct {
  width: 90%;
  height: 90%;
  max-width: 1200px;
  padding: 50px 20px;
  background-color: #fff;
  border-radius: 20px; }
  @media (min-width: 768px) {
    .IProduct {
      border-radius: 40px; } }
  .IProduct-Body {
    padding: 17px; }
    @media (min-width: 992px) {
      .IProduct-Body {
        display: flex;
        align-items: flex-start;
        height: 100%; } }
  .IProduct-Left {
    margin-bottom: 40px; }
    @media (min-width: 992px) {
      .IProduct-Left {
        width: 360px; } }
    .IProduct-Left .IProduct-Text {
      text-align: center; }
      @media (min-width: 992px) {
        .IProduct-Left .IProduct-Text {
          text-align: left; } }
  .IProduct-MHeading {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
    text-align: center; }
    @media (min-width: 992px) {
      .IProduct-MHeading {
        font-size: 28px;
        text-align: left; } }
  @media (min-width: 992px) {
    .IProduct-Right {
      width: calc(100% - 360px);
      height: 100%;
      max-height: 600px;
      padding-left: 45px; } }
  .IProduct-Slider {
    width: 100%;
    max-width: 360px;
    margin: 0 auto 30px; }
  .IProduct-Heading {
    margin-bottom: 20px;
    font-size: 16px; }
    @media (min-width: 768px) {
      .IProduct-Heading {
        font-size: 18px; } }
  .IProduct-Subheading {
    margin-bottom: 10px;
    font-size: 16px; }
    @media (min-width: 768px) {
      .IProduct-Subheading {
        font-size: 18px; } }
  .IProduct-Text {
    margin-bottom: 20px;
    font-size: 12px; }
    @media (min-width: 768px) {
      .IProduct-Text {
        font-size: 14px; } }
    .IProduct-Text:last-child {
      margin-bottom: 0; }
  .IProduct-Btn {
    width: 100%;
    max-width: 290px;
    height: 49px;
    margin: 30px auto 40px;
    font-weight: 400;
    color: #000;
    background: #E7E7E7; }
    @media (min-width: 768px) {
      .IProduct-Btn {
        font-size: 16px; } }
  .IProduct-Price {
    margin-bottom: 5px;
    font-size: 22px;
    text-align: center; }
    @media (min-width: 992px) {
      .IProduct-Price {
        text-align: left; } }

.IProductSlider-Main {
  width: 100%;
  height: 305px;
  margin-bottom: 40px; }
  .IProductSlider-Main-Img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center; }

.IProductSlider-SlideWrap {
  display: flex;
  justify-content: space-between; }

.IProductSlider-Slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90px;
  border: 1px solid #B8B8B8;
  cursor: pointer; }
  .IProductSlider-Slide-Img {
    display: block;
    width: 90%;
    height: 90%;
    object-fit: contain;
    object-position: center center; }

.IProductSliderNav {
  width: calc(100% - 50px);
  margin: 0 auto; }
  .IProductSliderNav .slick-current .IProductSlider-Slide {
    border-color: #d92321; }
  .IProductSliderNav .slick-slide {
    padding: 0 8px; }
  .IProductSliderNav .Slider-Arrow_simple {
    top: calc(50% - 8px);
    width: 10px;
    height: 17px; }
  .IProductSliderNav .Slider-Arrow_prev {
    left: -20px; }
  .IProductSliderNav .Slider-Arrow_next {
    right: -20px; }

.Header {
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2001; }
  @media (min-width: 992px) {
    .Header {
      padding-top: 40px;
      position: absolute;
      z-index: auto; } }
  .Header .Container {
    padding-right: 0; }
    @media (min-width: 992px) {
      .Header .Container {
        padding-right: 15px; } }
  .Header-Body {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (min-width: 992px) {
      .Header-Body {
        position: relative;
        z-index: 2; } }
  .Header-Logo {
    width: 110px; }
    @media (min-width: 480px) {
      .Header-Logo {
        width: 180px; } }
    @media (min-width: 992px) {
      .Header-Logo {
        width: 150px; } }
    @media (min-width: 1200px) {
      .Header-Logo {
        width: auto; } }
  .Header-Nav {
    margin-left: auto;
    margin-right: 20px; }
    @media (min-width: 1200px) {
      .Header-Nav {
        margin-right: 100px; } }
  .Header-Social {
    width: 95px;
    margin-left: auto;
    margin-right: 20px; }
    @media (min-width: 480px) {
      .Header-Social {
        width: 170px; } }
    @media (min-width: 768px) {
      .Header-Social {
        margin-right: 45px; } }
    @media (min-width: 992px) {
      .Header-Social {
        margin-left: 0; } }
  .Header-Info {
    display: none; }
    @media (min-width: 992px) {
      .Header-Info {
        display: block; } }
  @media (min-width: 992px) {
    .Header-DefmenuBtn {
      display: none; } }

.HeaderNav {
  display: none; }
  @media (min-width: 992px) {
    .HeaderNav {
      display: block; } }
  .HeaderNav-Link {
    margin-right: 10px;
    padding-bottom: 7px;
    font-size: 14px;
    color: #000;
    text-decoration: none;
    position: relative; }
    @media (min-width: 1200px) {
      .HeaderNav-Link {
        margin-right: 25px;
        font-size: 17px; } }
    .HeaderNav-Link:last-child {
      margin-right: 0; }
    .HeaderNav-Link:before {
      content: '';
      width: 100%;
      height: 2px;
      background-color: transparent;
      position: absolute;
      left: 0;
      bottom: 0; }
    .HeaderNav-Link:hover:before {
      background-color: #bc2232; }

.HeaderSocial {
  display: flex;
  justify-content: space-between; }
  .HeaderSocial-Item {
    text-decoration: none; }
    @media (min-width: 480px) {
      .HeaderSocial-Item {
        width: 35px;
        height: 35px;
        background-color: #ffffff;
        border-radius: 50%;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.07);
        display: flex;
        align-items: center;
        justify-content: center; } }
    .HeaderSocial-Item:last-child {
      margin-right: 0; }
  .HeaderSocial-Img {
    max-height: 15px; }
    @media (min-width: 480px) {
      .HeaderSocial-Img {
        max-height: none; } }

.HeaderInfo-Text {
  font-size: 14px;
  text-align: right;
  line-height: 1;
  display: block; }

.HeaderPhone {
  display: none; }
  @media (min-width: 992px) {
    .HeaderPhone {
      color: #000;
      text-decoration: none;
      display: block; } }
  .HeaderPhone-Number {
    display: block; }
    @media (min-width: 768px) {
      .HeaderPhone-Number {
        margin-bottom: 5px;
        font-size: 20px;
        font-weight: 700;
        white-space: nowrap; } }

.Intro-Body {
  padding-top: 75px;
  padding-bottom: 15px;
  position: relative;
  z-index: 1; }
  @media (min-width: 768px) {
    .Intro-Body {
      padding-top: 80px; } }
  @media (min-width: 992px) {
    .Intro-Body {
      padding-top: 120px;
      padding-bottom: 140px; } }

.Intro-Content {
  max-width: 230px; }
  @media (min-width: 480px) {
    .Intro-Content {
      max-width: 330px; } }
  @media (min-width: 768px) {
    .Intro-Content {
      max-width: 620px; } }

.Intro-Leaf_static {
  width: 30px;
  margin-left: 100px; }
  @media (min-width: 480px) {
    .Intro-Leaf_static {
      margin-left: 180px; } }
  @media (min-width: 768px) {
    .Intro-Leaf_static {
      width: auto;
      margin-left: 335px; } }
  @media (min-width: 992px) {
    .Intro-Leaf_static {
      width: 80px;
      margin-bottom: 0px; } }

.Intro-Heading {
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5; }
  @media (min-width: 480px) {
    .Intro-Heading {
      font-size: 24px; } }
  @media (min-width: 768px) {
    .Intro-Heading {
      margin-bottom: 35px;
      font-size: 46px;
      line-height: 1.4; } }
  @media (min-width: 480px) {
    .Intro-Heading br.phone {
      display: none; } }
  .Intro-Heading br.desktop {
    display: none; }
    @media (min-width: 480px) {
      .Intro-Heading br.desktop {
        display: block; } }

.Intro-Info {
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .Intro-Info {
      font-size: 14px; } }
  @media (min-width: 768px) {
    .Intro-Info {
      margin-bottom: 30px;
      font-size: 18px; } }
  .Intro-Info span {
    font-weight: 600; }

.Intro-List {
  margin-bottom: 26px; }
  @media (min-width: 768px) {
    .Intro-List {
      margin-bottom: 35px; } }

.Intro-Btn {
  width: 185px;
  height: 45px;
  padding-bottom: 3px; }
  @media (min-width: 768px) {
    .Intro-Btn {
      width: 270px;
      height: 65px; } }

.Intro-Girl {
  width: 182px;
  position: absolute;
  top: 75px;
  left: 200px; }
  @media (min-width: 480px) {
    .Intro-Girl {
      width: 210px;
      left: 300px; } }
  @media (min-width: 768px) {
    .Intro-Girl {
      width: 300px;
      left: 550px; } }
  @media (min-width: 992px) {
    .Intro-Girl {
      width: auto;
      left: auto;
      right: 0; } }
  @media (min-width: 1200px) {
    .Intro-Girl {
      top: 45px;
      right: 30px; } }

.Intro-Simg {
  display: none; }
  @media (min-width: 1600px) {
    .Intro-Simg {
      display: block;
      position: absolute; } }
  @media (min-width: 1600px) {
    .Intro-Simg_left {
      top: 330px;
      left: 0; } }
  @media (min-width: 1600px) {
    .Intro-Simg_right {
      top: 0;
      right: 0; } }

.IntroList-Item {
  margin-bottom: 5px;
  padding-left: 20px;
  font-size: 11px;
  position: relative; }
  @media (min-width: 480px) {
    .IntroList-Item {
      margin-bottom: 10px;
      padding-left: 30px;
      font-size: 14px; } }
  @media (min-width: 768px) {
    .IntroList-Item {
      margin-bottom: 15px;
      padding-left: 45px;
      font-size: 18px; } }
  .IntroList-Item:last-child {
    margin-bottom: 0; }
  .IntroList-Item:before {
    content: '';
    width: 13px;
    height: 11px;
    background-image: url(/img/check.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 3px;
    left: 0; }
    @media (min-width: 480px) {
      .IntroList-Item:before {
        width: 18px;
        height: 15px; } }
    @media (min-width: 768px) {
      .IntroList-Item:before {
        width: 25px;
        height: 22px; } }

@media (min-width: 575px) {
  .GiveChance-Body {
    padding-top: 50px;
    position: relative; } }

.GiveChance-SpecImgWrap {
  width: 320px;
  margin: 0 auto 10px; }
  @media (min-width: 575px) {
    .GiveChance-SpecImgWrap {
      position: absolute;
      left: 350px;
      bottom: 60px; } }
  @media (min-width: 768px) {
    .GiveChance-SpecImgWrap {
      width: 420px;
      left: 550px; } }
  @media (min-width: 992px) {
    .GiveChance-SpecImgWrap {
      left: 600px;
      bottom: 20px; } }
  @media (min-width: 1200px) {
    .GiveChance-SpecImgWrap {
      width: 641px;
      right: -260px;
      bottom: 0; } }

.GiveChance-SpecImg {
  width: 100%;
  display: block; }

@media (min-width: 575px) {
  .GiveChance-Content {
    max-width: 355px;
    position: relative;
    z-index: 2; } }

@media (min-width: 768px) {
  .GiveChance-Content {
    max-width: 760px; } }

.GiveChance-Heading {
  margin-bottom: 10px;
  text-align: center; }
  @media (min-width: 575px) {
    .GiveChance-Heading {
      text-align: left; } }
  @media (min-width: 768px) {
    .GiveChance-Heading {
      margin-bottom: 30px; } }
  @media (min-width: 575px) {
    .GiveChance-Heading br {
      display: none; } }

.GiveChance-Subheading {
  margin-bottom: 40px;
  text-align: center; }
  @media (min-width: 575px) {
    .GiveChance-Subheading {
      text-align: left; } }
  @media (min-width: 768px) {
    .GiveChance-Subheading {
      margin-bottom: 60px;
      font-size: 18px; } }

@media (min-width: 768px) {
  .GiveChance-Partners {
    max-width: 555px; } }

.GiveChancePartners {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .GiveChancePartners-Item {
    width: 33.33%;
    margin-bottom: 20px;
    display: flex;
    align-items: center; }
    @media (min-width: 768px) {
      .GiveChancePartners-Item {
        margin-bottom: 35px; } }
    @media (min-width: 992px) {
      .GiveChancePartners-Item {
        width: 25%; } }
    @media (min-width: 992px) {
      .GiveChancePartners-Item:nth-child(1) {
        order: 2; } }
    .GiveChancePartners-Item:nth-child(1) .GiveChancePartners-Img {
      width: 58px; }
    @media (min-width: 992px) {
      .GiveChancePartners-Item:nth-child(2) {
        order: 7; } }
    .GiveChancePartners-Item:nth-child(2) .GiveChancePartners-Img {
      width: 88px; }
    @media (min-width: 992px) {
      .GiveChancePartners-Item:nth-child(3) {
        order: 10; } }
    .GiveChancePartners-Item:nth-child(3) .GiveChancePartners-Img {
      width: 79px; }
    @media (min-width: 992px) {
      .GiveChancePartners-Item:nth-child(4) {
        order: 9; } }
    .GiveChancePartners-Item:nth-child(4) .GiveChancePartners-Img {
      width: 58px; }
    @media (min-width: 992px) {
      .GiveChancePartners-Item:nth-child(5) {
        order: 6; } }
    .GiveChancePartners-Item:nth-child(5) .GiveChancePartners-Img {
      width: 90px; }
    .GiveChancePartners-Item:nth-child(6) {
      width: 17.16%; }
      @media (min-width: 992px) {
        .GiveChancePartners-Item:nth-child(6) {
          width: 44px;
          order: 4; } }
      .GiveChancePartners-Item:nth-child(6) .GiveChancePartners-Img {
        width: 31px; }
    .GiveChancePartners-Item:nth-child(7) {
      width: 16.16%; }
      @media (min-width: 992px) {
        .GiveChancePartners-Item:nth-child(7) {
          width: 44px;
          order: 8; } }
      .GiveChancePartners-Item:nth-child(7) .GiveChancePartners-Img {
        width: 30px; }
    @media (min-width: 992px) {
      .GiveChancePartners-Item:nth-child(8) {
        order: 1; } }
    .GiveChancePartners-Item:nth-child(8) .GiveChancePartners-Img {
      width: 58px; }
    @media (min-width: 992px) {
      .GiveChancePartners-Item:nth-child(9) {
        order: 5; } }
    .GiveChancePartners-Item:nth-child(9) .GiveChancePartners-Img {
      width: 86px; }
    @media (min-width: 992px) {
      .GiveChancePartners-Item:nth-child(10) {
        order: 3; } }
    .GiveChancePartners-Item:nth-child(10) .GiveChancePartners-Img {
      width: 80px; }
    @media (min-width: 992px) {
      .GiveChancePartners-Item:nth-child(11) {
        order: 11; } }
    .GiveChancePartners-Item:nth-child(11) .GiveChancePartners-Img {
      width: 90px; }
    @media (min-width: 992px) {
      .GiveChancePartners-Item:nth-child(12) {
        width: 40px;
        order: 12; } }
  .GiveChancePartners-Img {
    display: block; }
    @media (min-width: 768px) {
      .GiveChancePartners-Img {
        width: auto !important; } }

.HzDecor-Body {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.HzDecor-Line {
  width: calc(50% - 60px);
  height: 5px;
  background-image: url(/img/HzDecor-Line.png);
  background-repeat: repeat-x; }
  @media (min-width: 992px) {
    .HzDecor-Line {
      width: calc(50% - 245px); } }

.HzDecor-Img {
  width: 69px; }
  @media (min-width: 992px) {
    .HzDecor-Img {
      width: 160px; } }

.ForThose-Body {
  padding-top: 35px; }
  @media (min-width: 768px) {
    .ForThose-Body {
      display: flex; } }

.ForThose-Content {
  max-width: 292px;
  margin: 0 auto 55px;
  display: flex;
  align-items: flex-start; }
  @media (min-width: 575px) {
    .ForThose-Content {
      max-width: 455px; } }
  @media (min-width: 768px) {
    .ForThose-Content {
      width: 50%;
      max-width: none;
      padding-top: 40px; } }

.ForThose-ImgText {
  width: 87px; }
  @media (min-width: 768px) {
    .ForThose-ImgText {
      width: 105px;
      margin-top: 5px; } }
  @media (min-width: 992px) {
    .ForThose-ImgText {
      width: auto; } }

.ForThose-List {
  width: calc(100% - 87px);
  padding-left: 20px; }
  @media (min-width: 992px) {
    .ForThose-List {
      padding-left: 30px; } }
  @media (min-width: 1200px) {
    .ForThose-List {
      padding-left: 55px; } }

@media (min-width: 768px) {
  .ForThose-SliderWrap {
    width: 50%; } }

.ForThose-Heading {
  margin-bottom: 15px;
  text-align: center; }
  @media (min-width: 768px) {
    .ForThose-Heading {
      font-size: 32px; } }

.ForThose-Leaf {
  display: none; }

.ForThoseList-Item {
  margin-bottom: 13px;
  padding-left: 25px;
  position: relative; }
  @media (min-width: 575px) {
    .ForThoseList-Item {
      margin-bottom: 30px; } }
  @media (min-width: 992px) {
    .ForThoseList-Item {
      padding-left: 35px;
      font-size: 22px; } }
  @media (min-width: 1200px) {
    .ForThoseList-Item {
      margin-bottom: 35px;
      padding-left: 45px; } }
  .ForThoseList-Item:before {
    content: '';
    width: 13px;
    height: 11px;
    background-image: url(/img/check.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 3px;
    left: 0; }
    @media (min-width: 480px) {
      .ForThoseList-Item:before {
        width: 18px;
        height: 15px; } }
    @media (min-width: 992px) {
      .ForThoseList-Item:before {
        top: 9px; } }

@media (min-width: 1200px) {
  .WeKnow {
    position: relative; } }

.WeKnow-Body {
  padding-top: 30px;
  padding-bottom: 40px; }

.WeKnow-Heading {
  margin-bottom: 25px;
  text-align: center; }
  @media (min-width: 768px) {
    .WeKnow-Heading {
      margin-bottom: 30px;
      line-height: 1.2;
      text-align: left; } }
  .WeKnow-Heading span {
    font-weight: 300; }

.WeKnow-Subheading {
  margin-bottom: 50px;
  font-weight: 300;
  text-align: center; }
  @media (min-width: 768px) {
    .WeKnow-Subheading {
      font-size: 24px;
      font-weight: 400;
      text-align: left; } }
  @media (min-width: 992px) {
    .WeKnow-Subheading {
      margin-bottom: 30px; } }

@media (min-width: 768px) {
  .WeKnow-Content {
    display: flex; } }

@media (min-width: 768px) {
  .WeKnow-Products {
    width: 50%; } }

@media (min-width: 992px) {
  .WeKnow-Products {
    padding-top: 40px; } }

.WeKnow-SimgWrap {
  max-width: 300px;
  margin: 35px auto 0; }
  @media (min-width: 992px) {
    .WeKnow-SimgWrap {
      position: relative;
      margin-top: 0; } }
  @media (min-width: 1200px) {
    .WeKnow-SimgWrap {
      max-width: none; } }

.WeKnow-Simg {
  max-width: 100%; }
  .WeKnow-Simg-Info {
    margin-top: 28px;
    color: #737373;
    text-align: center; }
    @media (min-width: 992px) {
      .WeKnow-Simg-Info {
        position: absolute;
        bottom: 0;
        margin: 0;
        font-size: 14px;
        color: #000; } }

.WeKnow-AddInfo {
  position: relative;
  margin-top: 28px;
  padding: 20px 34px 18px 71px;
  background: #FFFFFF;
  border-radius: 21px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.17);
  overflow: hidden; }
  @media (min-width: 768px) {
    .WeKnow-AddInfo {
      margin-top: 55px; } }
  @media (min-width: 1200px) {
    .WeKnow-AddInfo {
      padding: 25px 25px 25px 110px;
      box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.07); } }
  .WeKnow-AddInfo:before {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 100%;
    padding-bottom: 3px;
    font-size: 22px;
    font-weight: 900;
    color: #FFFFFF;
    background-color: #FBBD00;
    content: '+'; }
    @media (min-width: 1200px) {
      .WeKnow-AddInfo:before {
        top: -3px;
        left: -10px;
        width: 80px;
        height: 80px;
        font-size: 31px;
        border-radius: 50%; } }

.WeKnow-Stamp {
  margin-top: 35px; }
  @media (min-width: 768px) {
    .WeKnow-Stamp {
      margin-top: 60px; } }

.WeKnow-Leaf {
  display: none; }
  @media (min-width: 1600px) {
    .WeKnow-Leaf {
      position: absolute;
      display: block; } }
  @media (min-width: 1600px) {
    .WeKnow-Leaf_left {
      left: 0;
      bottom: 510px; } }
  @media (min-width: 1600px) {
    .WeKnow-Leaf_right {
      top: 100px;
      right: 0; } }

.WeKnowProducts-Item {
  position: relative;
  margin-bottom: 30px;
  padding-left: 87px; }
  @media (min-width: 768px) {
    .WeKnowProducts-Item {
      padding-left: 0; } }
  @media (min-width: 1200px) {
    .WeKnowProducts-Item {
      margin-bottom: 45px; } }
  .WeKnowProducts-Item:last-child {
    margin-bottom: 0; }

.WeKnowProducts-Icon {
  position: absolute;
  top: 10px;
  left: 0; }
  @media (min-width: 768px) {
    .WeKnowProducts-Icon {
      position: static;
      margin-left: 10px; } }

.WeKnowProducts-Heading {
  font-size: 24px;
  font-weight: 300; }
  @media (min-width: 768px) {
    .WeKnowProducts-Heading {
      font-size: 40px; } }
  @media (min-width: 1200px) {
    .WeKnowProducts-Heading {
      font-size: 62px; } }
  .WeKnowProducts-Heading b {
    color: #FBBD00;
    font-weight: 900; }

.WeKnowProducts-Text {
  margin-top: 5px; }
  @media (min-width: 768px) {
    .WeKnowProducts-Text {
      margin-top: 15px;
      font-size: 18px; } }
  .WeKnowProducts-Text br {
    display: none; }
    @media (min-width: 992px) {
      .WeKnowProducts-Text br {
        display: block; } }

.WeKnowStamp {
  display: flex;
  justify-content: space-between; }
  .WeKnowStamp-Item {
    width: 50px; }
    @media (min-width: 768px) {
      .WeKnowStamp-Item {
        width: auto; } }
  .WeKnowStamp-Img {
    width: 100%; }

.HighEfficiency {
  background-color: #f8f3f0;
  position: relative; }
  @media (min-width: 1200px) {
    .HighEfficiency {
      background-image: url(/img/HighEfficiency-Bg.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed; } }
  .HighEfficiency-Body {
    padding-top: 40px;
    padding-bottom: 100px;
    position: relative;
    z-index: 1; }
    @media (min-width: 992px) {
      .HighEfficiency-Body {
        padding-top: 45px;
        padding-bottom: 60px; } }
  .HighEfficiency-Heading {
    font-size: 18px;
    font-weight: 700;
    text-align: center; }
    @media (min-width: 992px) {
      .HighEfficiency-Heading {
        font-size: 32px; } }
  .HighEfficiency-Subheading {
    font-size: 18px;
    text-align: center;
    line-height: 1.3; }
    @media (min-width: 992px) {
      .HighEfficiency-Subheading {
        font-size: 32px; } }
  .HighEfficiency-List {
    margin-top: 25px; }
    @media (min-width: 992px) {
      .HighEfficiency-List {
        margin-top: 40px; } }
  .HighEfficiency-Leaf {
    position: absolute; }
    .HighEfficiency-Leaf_left {
      left: 0;
      bottom: 0; }
    @media (min-width: 992px) {
      .HighEfficiency-Leaf_phone {
        display: none; } }

@media (min-width: 992px) {
  .HighEfficiencyList {
    display: flex;
    justify-content: space-between; } }

.HighEfficiencyList-Item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 62px;
  max-width: 327px;
  margin: 0 auto 15px;
  background-image: url(/img/HighEfficiencyList-Item-Bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%; }
  @media (min-width: 992px) {
    .HighEfficiencyList-Item {
      width: 275px;
      height: 90px;
      background-image: url(/img/HighEfficiencyList-Item-Bg_desktop.png); } }
  .HighEfficiencyList-Item:before {
    position: absolute;
    left: 50%;
    bottom: -24px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    padding-top: 5px;
    font-size: 16px;
    font-weight: 900;
    line-height: 1;
    color: #FFFFFF;
    background-color: #FBBD00;
    border-radius: 50%;
    transform: translateX(-50%);
    content: '+'; }
    @media (min-width: 992px) {
      .HighEfficiencyList-Item:before {
        left: 100%;
        bottom: 50%;
        transform: translate(-12px, 50%); } }
    @media (min-width: 1200px) {
      .HighEfficiencyList-Item:before {
        width: 51px;
        height: 51px;
        padding-top: 0;
        padding-bottom: 3px;
        font-size: 20px; } }
  .HighEfficiencyList-Item:last-child {
    margin-bottom: 0; }
    .HighEfficiencyList-Item:last-child:before {
      display: none; }

.HighEfficiencyList-Text {
  font-size: 16px;
  font-weight: 500; }

.HighEfficiencyList-Leaf {
  position: absolute;
  top: -15px;
  right: 0;
  width: 55px; }

@media (min-width: 1600px) {
  .Product {
    position: relative; } }

.Product-Body {
  padding-top: 55px;
  padding-bottom: 50px; }
  @media (min-width: 1200px) {
    .Product-Body {
      position: relative;
      padding-top: 100px;
      padding-bottom: 160px; } }

@media (min-width: 1200px) {
  .Product-List {
    position: relative;
    z-index: 2; } }

.Product-Card {
  width: 100%;
  height: 100%; }

.Product-Leaf {
  display: none; }
  @media (min-width: 1600px) {
    .Product-Leaf {
      position: absolute;
      display: block; } }
  @media (min-width: 1600px) {
    .Product-Leaf_left {
      top: 335px;
      left: 0; } }
  .Product-Leaf_right {
    right: 0;
    bottom: 260px; }

@media (min-width: 768px) {
  .ProductList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

.ProductList-Item {
  width: 100%;
  max-width: 380px;
  margin: 0 auto 50px; }
  @media (min-width: 768px) {
    .ProductList-Item {
      max-width: 360px; } }
  @media (min-width: 992px) {
    .ProductList-Item {
      width: calc(33.33% - 25px*2/3);
      max-width: none;
      margin: 0 0 50px; } }
  @media (min-width: 1200px) {
    .ProductList-Item {
      width: calc(33.33% - 25px*2/3);
      margin-bottom: 80px; } }
  .ProductList-Item:last-child {
    margin-bottom: 0; }
  @media (min-width: 992px) {
    .ProductList-Item:nth-last-child(2) {
      margin-bottom: 0; } }
  @media (min-width: 992px) {
    .ProductList-Item_big {
      width: calc(50% - 25px); } }

.ProductCard {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 16px;
  box-shadow: 8px 0 40px rgba(0, 0, 0, 0.1);
  overflow: hidden; }
  @media (min-width: 992px) {
    .ProductCard {
      border-radius: 40px; } }
  .ProductCard-Preview {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 240px;
    background-color: #FFF2D3;
    overflow: hidden; }
  .ProductCard-PreviewImg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center; }
    .ProductCard-PreviewImg_type_1 {
      width: 268px;
      object-position: center 20px; }
    .ProductCard-PreviewImg_type_2 {
      width: 239px;
      object-position: center 35px; }
    .ProductCard-PreviewImg_type_3 {
      width: 286px;
      object-position: center 45px; }
    .ProductCard-PreviewImg_type_4 {
      width: 258px; }
      @media (min-width: 992px) {
        .ProductCard-PreviewImg_type_4 {
          width: 204px; } }
    .ProductCard-PreviewImg_type_5 {
      width: 138px; }
      @media (min-width: 992px) {
        .ProductCard-PreviewImg_type_5 {
          width: 173px; } }
  .ProductCard-Content {
    padding: 25px 10px 30px; }
  .ProductCard-Heading {
    font-size: 20px;
    font-weight: 700;
    text-align: center; }
    @media (min-width: 768px) {
      .ProductCard-Heading {
        font-size: 22px; } }
  .ProductCard-Info {
    margin-top: 15px;
    text-align: center; }
    @media (min-width: 768px) {
      .ProductCard-Info {
        font-size: 16px; } }
  .ProductCard-Btn {
    width: 188px;
    height: 45px;
    margin: 20px auto 0;
    padding-bottom: 3px; }
    @media (min-width: 768px) {
      .ProductCard-Btn {
        width: 240px;
        height: 55px;
        font-size: 16px; } }
  .ProductCard-Leaf {
    display: none; }
    @media (min-width: 1200px) {
      .ProductCard-Leaf {
        display: block; } }
    @media (min-width: 1200px) {
      .ProductCard-Leaf_spec {
        position: absolute;
        top: 85px;
        right: -225px; } }

@media (min-width: 992px) {
  .ProductCard_big {
    display: flex; } }

@media (min-width: 992px) {
  .ProductCard_big .ProductCard-Preview {
    width: 45%;
    height: 100%; }
  .ProductCard_big .ProductCard-Content {
    width: 55%;
    padding: 40px 20px 60px 30px; }
  .ProductCard_big .ProductCard-Heading {
    text-align: left; }
  .ProductCard_big .ProductCard-Info {
    text-align: left; }
  .ProductCard_big .ProductCard-Btn {
    width: 205px;
    height: 45px;
    margin: 20px 0 0;
    font-size: 14px; } }

.WhyWeSert {
  background-color: #F6F2F1; }
  @media (min-width: 1200px) {
    .WhyWeSert {
      background-image: url(/img/WhyWeSert-Bg.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed; } }
  .WhyWeSert-Body {
    padding-top: 45px;
    padding-bottom: 40px; }
    @media (min-width: 1200px) {
      .WhyWeSert-Body {
        padding-top: 80px;
        padding-bottom: 100px; } }
  .WhyWeSert-List {
    max-width: 243px;
    margin: 0 auto; }
    @media (min-width: 575px) {
      .WhyWeSert-List {
        max-width: 300px; } }
    @media (min-width: 768px) {
      .WhyWeSert-List {
        max-width: none; } }

@media (min-width: 768px) {
  .WhyWeSertList {
    display: flex;
    justify-content: space-between; } }

.WhyWeSertList-Item {
  display: flex;
  align-items: center;
  margin-bottom: 25px; }
  @media (min-width: 768px) {
    .WhyWeSertList-Item {
      display: block;
      width: calc(33.33% - 10px);
      margin-bottom: 0; } }
  @media (min-width: 1200px) {
    .WhyWeSertList-Item {
      width: auto; } }
  .WhyWeSertList-Item:last-child {
    margin-bottom: 0; }

.WhyWeSertList-Label {
  padding-bottom: 5px;
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  color: #FBBD00; }
  @media (min-width: 768px) {
    .WhyWeSertList-Label {
      margin-bottom: 10px;
      padding-bottom: 0; } }
  @media (min-width: 1200px) {
    .WhyWeSertList-Label {
      margin-bottom: 20px;
      font-size: 52px;
      text-shadow: 0 0 2px #F6F2F1, 0 0 2px #F6F2F1, 0 0 2px #F6F2F1, 0 0 2px #F6F2F1; } }

.WhyWeSertList-Text {
  margin-left: 15px; }
  @media (min-width: 768px) {
    .WhyWeSertList-Text {
      margin-left: 0; } }
  @media (min-width: 1200px) {
    .WhyWeSertList-Text {
      font-size: 18px;
      text-shadow: 0 0 2px #F6F2F1, 0 0 2px #F6F2F1, 0 0 2px #F6F2F1, 0 0 2px #F6F2F1; } }

.AfterUse-Body {
  padding-top: 50px;
  padding-bottom: 65px; }
  @media (min-width: 992px) {
    .AfterUse-Body {
      display: flex; } }
  @media (min-width: 1200px) {
    .AfterUse-Body {
      padding-top: 100px;
      padding-bottom: 130px; } }

.AfterUse-Left {
  margin-bottom: 55px; }
  @media (min-width: 1200px) {
    .AfterUse-Left {
      width: calc(100% - 526px);
      padding-right: 60px; } }

.AfterUse-Right {
  width: 100%; }
  @media (min-width: 992px) {
    .AfterUse-Right {
      padding-top: 50px; } }
  @media (min-width: 1200px) {
    .AfterUse-Right {
      width: 526px;
      padding-top: 20px; } }

.AfterUse-Heading {
  margin-bottom: 20px;
  text-align: center; }
  @media (min-width: 768px) {
    .AfterUse-Heading {
      margin-bottom: 35px; } }
  @media (min-width: 992px) {
    .AfterUse-Heading {
      line-height: 1.2;
      text-align: left; } }

.AfterUse-SpecImg {
  display: block;
  width: 100%;
  max-width: 350px;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .AfterUse-SpecImg {
      max-width: 526px; } }

.AfterUseList-Item {
  position: relative;
  margin-bottom: 15px;
  padding-left: 24px; }
  @media (min-width: 768px) {
    .AfterUseList-Item {
      margin-bottom: 30px;
      padding-left: 60px;
      font-size: 22px; } }
  .AfterUseList-Item:last-child {
    margin-bottom: 0; }
  .AfterUseList-Item:before {
    content: '';
    width: 13px;
    height: 11px;
    background-image: url(/img/check.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 2px;
    left: 0; }
    @media (min-width: 480px) {
      .AfterUseList-Item:before {
        width: 18px;
        height: 15px; } }
    @media (min-width: 768px) {
      .AfterUseList-Item:before {
        width: 25px;
        height: 22px; } }

.WhyWe {
  background-color: #F6F2F1; }
  @media (min-width: 1200px) {
    .WhyWe {
      background-image: url(/img/WhyWe-Bg.jpg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; } }
  .WhyWe-Body {
    position: relative;
    padding-top: 35px;
    padding-bottom: 45px; }
    @media (min-width: 992px) {
      .WhyWe-Body {
        padding-top: 50px;
        padding-bottom: 60px; } }
  .WhyWe-Leaf {
    position: absolute; }
    .WhyWe-Leaf_left {
      width: 102px;
      top: -50px;
      right: 25px; }
      @media (min-width: 1200px) {
        .WhyWe-Leaf_left {
          width: auto;
          top: -105px;
          left: auto;
          right: calc(100% + 60px); } }

@media (min-width: 768px) {
  .WhyWeList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

.WhyWeList-Item {
  display: flex;
  align-items: center;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .WhyWeList-Item {
      width: 50%; } }
  @media (min-width: 992px) {
    .WhyWeList-Item {
      display: block;
      width: auto;
      margin: 0;
      text-align: center; } }
  .WhyWeList-Item:last-child {
    margin-bottom: 0; }
  .WhyWeList-Item br {
    display: none; }
    @media (min-width: 768px) {
      .WhyWeList-Item br {
        display: block; } }

.WhyWeList-IconWrap {
  width: 68px;
  height: 68px; }
  @media (min-width: 992px) {
    .WhyWeList-IconWrap {
      width: 101px;
      height: 101px;
      margin: 0 auto 15px; } }

.WhyWeList-Icon {
  display: block;
  width: 100%;
  height: 100%; }

.WhyWeList-Text {
  width: calc(100% - 68px);
  padding-left: 25px; }
  @media (min-width: 992px) {
    .WhyWeList-Text {
      width: auto;
      padding-left: 0; } }

.SpecOffer {
  background: linear-gradient(27.07deg, #D37568 0%, #FFEB90 100%), #C4C4C4; }
  .SpecOffer-Body {
    position: relative;
    padding-top: 70px;
    padding-bottom: 55px; }
    @media (min-width: 992px) {
      .SpecOffer-Body {
        padding-top: 80px;
        padding-right: calc(100% - 550px);
        padding-bottom: 80px; } }
  .SpecOffer-New {
    position: absolute;
    top: 0;
    left: calc(50% - 72px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 47px;
    font-size: 13px;
    font-weight: 700;
    color: #FFFFFF;
    text-transform: uppercase;
    background: #DA2720;
    border-radius: 0px 0px 20px 20px; }
    @media (min-width: 992px) {
      .SpecOffer-New {
        left: auto;
        right: 0;
        width: 160px;
        height: 72px;
        font-size: 22px;
        text-transform: none; } }
  .SpecOffer-Heading {
    margin-bottom: 30px;
    color: #FFFFFF;
    text-align: center; }
    @media (min-width: 992px) {
      .SpecOffer-Heading {
        font-size: 36px;
        text-align: left; } }
  .SpecOffer-Simg {
    width: 100%;
    max-width: 310px;
    display: block; }
    .SpecOffer-Simg_phone {
      position: relative;
      left: -15px;
      margin: 0 auto; }
      @media (min-width: 992px) {
        .SpecOffer-Simg_phone {
          display: none; } }
    .SpecOffer-Simg_desktop {
      display: none; }
      @media (min-width: 992px) {
        .SpecOffer-Simg_desktop {
          display: block;
          position: absolute;
          top: 110px;
          right: 0;
          max-width: 400px; } }
      @media (min-width: 1200px) {
        .SpecOffer-Simg_desktop {
          max-width: 582px; } }
  .SpecOffer-Subheading {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    text-align: center; }
    @media (min-width: 992px) {
      .SpecOffer-Subheading {
        margin-bottom: 30px;
        font-size: 18px;
        text-align: left; } }
  .SpecOffer-Info {
    margin-bottom: 30px;
    color: #ffffff;
    text-align: center; }
    @media (min-width: 992px) {
      .SpecOffer-Info {
        position: relative;
        margin-bottom: 40px;
        padding-left: 40px;
        text-align: left; } }
    @media (min-width: 992px) {
      .SpecOffer-Info:before {
        content: '';
        width: 25px;
        height: 23px;
        background-image: url(/img/check_white.png);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 2px;
        left: 0; } }
  .SpecOffer-Btn {
    display: flex;
    width: 100%;
    max-width: 188px;
    height: 45px;
    margin: 0 auto;
    padding-bottom: 3px;
    font-size: 12px;
    background: #fff;
    color: #000; }
    @media (min-width: 768px) {
      .SpecOffer-Btn {
        max-width: 260px;
        height: 62px;
        font-size: 17px; } }

.CourseTable-Body {
  padding-top: 50px;
  padding-bottom: 25px; }
  @media (min-width: 992px) {
    .CourseTable-Body {
      padding-top: 75px;
      padding-bottom: 50px; } }

.CourseTable-Heading {
  margin-bottom: 15px;
  text-align: center; }

.CourseTable-Subheading {
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: 700;
  text-align: center; }
  @media (min-width: 992px) {
    .CourseTable-Subheading {
      margin-bottom: 55px;
      font-size: 22px; } }

.CourseTable-Table {
  width: 100%;
  margin: 0 auto; }
  .CourseTable-Table_phone {
    display: block;
    max-width: 320px; }
    @media (min-width: 992px) {
      .CourseTable-Table_phone {
        display: none; } }
  .CourseTable-Table_desktop {
    display: none; }
    @media (min-width: 992px) {
      .CourseTable-Table_desktop {
        display: block;
        max-width: 1196px; } }

.CourseTable-MinText {
  margin-top: 20px;
  font-size: 10px;
  color: #6B6B6B;
  text-align: center; }
  @media (min-width: 992px) {
    .CourseTable-MinText {
      margin-top: 40px;
      font-size: 14px; } }

@media (min-width: 1600px) {
  .Courses {
    position: relative; } }

.Courses-Body {
  padding-top: 45px;
  padding-bottom: 55px; }
  @media (min-width: 992px) {
    .Courses-Body {
      padding-top: 100px;
      padding-bottom: 165px;
      position: relative;
      z-index: 2; } }

@media (min-width: 992px) {
  .Courses-List {
    position: relative;
    z-index: 2; } }

.Courses-Simg {
  display: none; }
  @media (min-width: 992px) {
    .Courses-Simg {
      position: absolute;
      display: block; } }
  .Courses-Simg_bot {
    right: 50%;
    bottom: 0; }
  @media (min-width: 992px) {
    .Courses-Simg_right {
      left: 100%;
      bottom: 495px; } }

.Courses-Leaf {
  display: none; }
  @media (min-width: 1600px) {
    .Courses-Leaf_left {
      position: absolute;
      top: 120px;
      left: 0;
      display: block; } }

@media (min-width: 768px) {
  .CoursesList {
    display: flex;
    justify-content: space-between; } }

.CoursesList-Item {
  margin-bottom: 60px; }
  @media (min-width: 768px) {
    .CoursesList-Item {
      width: calc(33.33% - 10px); } }
  @media (min-width: 992px) {
    .CoursesList-Item {
      width: calc(33.33% - 30px*2/3); } }
  .CoursesList-Item:last-child {
    margin-bottom: 0; }

.CoursesCard {
  width: 100%;
  max-width: 380px;
  padding-bottom: 35px;
  margin: 0 auto;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #C1C1C1;
  border-radius: 29px; }
  @media (min-width: 992px) {
    .CoursesCard {
      max-width: none;
      margin: 0; } }
  .CoursesCard:before {
    position: absolute;
    top: 0;
    left: 50%;
    width: 24px;
    height: 26px;
    background-image: url(/img/CoursesCard-Hz.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transform: translate(-50%, -50%);
    content: ''; }
  .CoursesCard-Heading {
    padding: 30px 10px 20px;
    font-size: 20px;
    font-weight: 700;
    text-align: center; }
    @media (min-width: 480px) {
      .CoursesCard-Heading {
        padding-top: 35px;
        padding-bottom: 35px;
        font-size: 32px;
        line-height: 0.9; } }
    .CoursesCard-Heading br {
      display: none; }
      @media (min-width: 480px) {
        .CoursesCard-Heading br {
          display: block; } }
  .CoursesCard-Period {
    padding: 12px 10px 13px;
    font-size: 16px;
    text-align: center;
    background-color: #FFF5D9; }
    @media (min-width: 480px) {
      .CoursesCard-Period {
        font-size: 21px; } }
  .CoursesCard-Preview {
    width: 100%;
    height: 214px;
    background-color: #fcf5eb; }
    @media (min-width: 480px) {
      .CoursesCard-Preview {
        height: 249px; } }
  .CoursesCard-PreviewImg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center; }
  .CoursesCard-Characteristic {
    padding: 35px 34px 30px 25px; }
    @media (min-width: 480px) {
      .CoursesCard-Characteristic {
        padding-left: 65px; } }
  .CoursesCard-Gift {
    padding: 11px 10px 10px; }
  .CoursesCard-Price {
    padding: 20px 10px 15px; }
    @media (min-width: 480px) {
      .CoursesCard-Price {
        padding-bottom: 25px; } }
  .CoursesCard-Btn {
    width: 189px;
    height: 45px;
    margin: 0 auto;
    padding-bottom: 3px; }
    @media (min-width: 480px) {
      .CoursesCard-Btn {
        width: 100%;
        max-width: 255px;
        height: 50px;
        padding-bottom: 4px;
        font-size: 15px; } }

.CoursesCardCharacteristic-Item {
  position: relative;
  margin-bottom: 15px;
  padding-left: 27px; }
  @media (min-width: 480px) {
    .CoursesCardCharacteristic-Item {
      margin-bottom: 25px;
      font-size: 14px; } }
  .CoursesCardCharacteristic-Item:last-child {
    margin-bottom: 0; }
  .CoursesCardCharacteristic-Item:before {
    position: absolute;
    top: 2px;
    left: 0;
    width: 13px;
    height: 11px;
    background-image: url(/img/check.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    content: ''; }
    @media (min-width: 480px) {
      .CoursesCardCharacteristic-Item:before {
        width: 18px;
        height: 15px; } }

.CoursesCardGift {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF5D9; }
  .CoursesCardGift-Text {
    line-height: 1.3;
    text-align: right; }
    @media (min-width: 480px) {
      .CoursesCardGift-Text {
        font-size: 16px; } }
  .CoursesCardGift-Img {
    height: 50px;
    margin-left: 20px; }
    @media (min-width: 480px) {
      .CoursesCardGift-Img {
        height: 80px; } }

.CoursesCardPrice {
  display: flex;
  align-items: center;
  justify-content: center; }
  .CoursesCardPrice-Nums {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3px; }
    @media (min-width: 480px) {
      .CoursesCardPrice-Nums {
        flex-direction: column;
        padding: 0; } }
  .CoursesCardPrice-Old {
    position: relative;
    padding-top: 2px;
    font-size: 13px;
    font-weight: 700;
    color: #B0B0B0; }
    @media (min-width: 480px) {
      .CoursesCardPrice-Old {
        padding: 0;
        font-size: 17px; } }
    .CoursesCardPrice-Old:before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #DA2720;
      content: ''; }
  .CoursesCardPrice-New {
    margin-left: 15px;
    font-size: 18px;
    font-weight: 700; }
    @media (min-width: 480px) {
      .CoursesCardPrice-New {
        margin-left: 0;
        padding: 0;
        font-size: 25px;
        line-height: 1; } }
  .CoursesCardPrice-Discount {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    margin-left: 18px;
    padding-bottom: 2px;
    font-size: 10px;
    font-weight: 700;
    color: #FFFFFF;
    background-color: #DA2720;
    border-radius: 50%; }
    @media (min-width: 480px) {
      .CoursesCardPrice-Discount {
        width: 42px;
        height: 42px;
        font-size: 13px; } }

.CoursesCard_base:before {
  width: 24px;
  height: 26px; }

.CoursesCard_base .CoursesCard-Preview {
  height: 250px; }

@media (min-width: 768px) {
  .CoursesCard_optimal {
    border-color: #eb3332;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15); } }

@media (min-width: 992px) {
  .CoursesCard_optimal {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15); } }

.CoursesCard_optimal:before {
  width: 33px;
  height: 36px; }

.CoursesCard_optimal .CoursesCard-Preview {
  height: 250px; }

.CoursesCard_intense:before {
  width: 44px;
  height: 49px; }

.CoursesCard_intense .CoursesCard-Preview {
  height: 250px; }

.SpecGift-Body {
  padding-bottom: 90px; }
  @media (min-width: 992px) {
    .SpecGift-Body {
      padding-bottom: 80px; } }

.SpecGiftCard {
  text-align: center; }
  @media (min-width: 992px) {
    .SpecGiftCard {
      position: relative;
      padding: 30px 0 35px 60px;
      background-image: url(/img/SpecGiftCard-Circle.png);
      background-repeat: no-repeat;
      background-position: center right;
      text-align: left;
      box-shadow: 0 0 49px rgba(0, 0, 0, 0.11); } }
  @media (min-width: 1200px) {
    .SpecGiftCard {
      padding-left: 110px; } }
  .SpecGiftCard-Heading {
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .SpecGiftCard-Heading {
        margin-bottom: 5px; } }
  .SpecGiftCard-Subheading {
    margin-bottom: 10px;
    font-size: 12px; }
    @media (min-width: 992px) {
      .SpecGiftCard-Subheading {
        margin-bottom: 30px;
        font-size: 18px; } }
    @media (min-width: 992px) {
      .SpecGiftCard-Subheading br {
        display: none; } }
  .SpecGiftCard-MinText {
    margin-bottom: 20px;
    font-size: 10px;
    color: #8A8A8A; }
    @media (min-width: 992px) {
      .SpecGiftCard-MinText {
        margin-bottom: 0;
        font-size: 13px; } }
  .SpecGiftCard-SpecImgWrap {
    width: 227px;
    height: 195px;
    margin: 0 auto;
    padding-top: 60px;
    background-color: rgba(218, 39, 32, 0.46);
    border-radius: 50%; }
    @media (min-width: 992px) {
      .SpecGiftCard-SpecImgWrap {
        position: absolute;
        top: -35px;
        right: -50px;
        width: initial;
        height: initial;
        padding: 0;
        background-color: transparent; } }
  .SpecGiftCard-SpecImg {
    display: block;
    width: 196px;
    margin: 0 auto; }
    @media (min-width: 992px) {
      .SpecGiftCard-SpecImg {
        width: auto; } }

.HowOrder {
  background-color: #F6F2F1; }
  @media (min-width: 992px) {
    .HowOrder {
      background-image: url(/img/HowOrder-Bg.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover; } }
  .HowOrder-Body {
    padding-top: 30px;
    padding-bottom: 55px; }
    @media (min-width: 992px) {
      .HowOrder-Body {
        padding-top: 45px;
        padding-bottom: 85px; } }
  .HowOrder-Heading {
    margin-bottom: 10px;
    text-align: center; }
    @media (min-width: 992px) {
      .HowOrder-Heading {
        margin-bottom: 55px; } }

@media (min-width: 992px) {
  .HowOrderList {
    display: flex;
    justify-content: space-between;
    max-width: 1050px;
    margin: 0 auto; } }

.HowOrderList-Item {
  margin-bottom: 40px;
  text-align: center; }
  @media (min-width: 992px) {
    .HowOrderList-Item {
      width: 50%;
      max-width: 460px;
      text-align: left; } }
  .HowOrderList-Item:last-child {
    margin-bottom: 0; }

.HowOrderList-Heading {
  position: relative;
  margin-bottom: 5px;
  padding-top: 43px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1; }
  @media (min-width: 992px) {
    .HowOrderList-Heading {
      margin-bottom: 25px;
      padding-top: 0;
      padding-left: 80px;
      font-size: 30px; } }
  .HowOrderList-Heading:before {
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    padding-top: 3px;
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    background-color: #FBBD00;
    border-radius: 50%;
    transform: translateX(-50%);
    content: '01'; }
    @media (min-width: 992px) {
      .HowOrderList-Heading:before {
        top: -13px;
        left: 0;
        width: 60px;
        height: 60px;
        padding-top: 0;
        font-size: 22px;
        transform: none; } }

.HowOrderList-Text {
  margin-bottom: 13px; }
  @media (min-width: 992px) {
    .HowOrderList-Text {
      font-size: 22px;
      line-height: 1.3; } }
  .HowOrderList-Text br {
    display: none; }
    @media (min-width: 992px) {
      .HowOrderList-Text br {
        display: block; } }

.HowOrderList-MinText {
  margin-bottom: 20px;
  font-size: 10px;
  color: #7E7E7E; }
  @media (min-width: 992px) {
    .HowOrderList-MinText {
      margin-bottom: 25px;
      font-size: 14px; } }

.HowOrderList-Btn {
  width: 188px;
  height: 45px;
  margin: 0 auto;
  padding-bottom: 4px; }
  @media (min-width: 768px) {
    .HowOrderList-Btn {
      width: 255px;
      height: 50px;
      font-size: 15px; } }
  @media (min-width: 992px) {
    .HowOrderList-Btn {
      margin: 0; } }

.HowOrderList-Item:nth-child(2) .HowOrderList-Heading:before {
  content: '02'; }

.Delivery-Body {
  padding-top: 55px;
  padding-bottom: 25px; }
  @media (min-width: 992px) {
    .Delivery-Body {
      display: flex;
      padding-top: 80px;
      padding-bottom: 0; } }

.Delivery-Left {
  margin-bottom: 35px; }
  @media (min-width: 992px) {
    .Delivery-Left {
      margin-bottom: 0; } }

.Delivery-Right {
  max-width: 445px;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .Delivery-Right {
      margin-right: 0;
      padding-top: 40px; } }

.Delivery-Heading {
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 700;
  text-align: center; }
  @media (min-width: 768px) {
    .Delivery-Heading {
      font-size: 30px;
      text-align: left; } }

.Delivery-Phone {
  display: block;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  text-decoration: none; }
  @media (min-width: 768px) {
    .Delivery-Phone {
      display: inline-block;
      margin-bottom: 10px;
      font-size: 30px;
      text-align: left; } }

.DeliveryList-Item {
  position: relative;
  margin-bottom: 15px;
  padding-left: 30px; }
  @media (min-width: 768px) {
    .DeliveryList-Item {
      padding-left: 45px;
      font-size: 22px; } }
  .DeliveryList-Item:last-child {
    margin-bottom: 0; }
  .DeliveryList-Item:before {
    content: '';
    width: 13px;
    height: 11px;
    background-image: url(/img/check.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 2px;
    left: 0; }
    @media (min-width: 480px) {
      .DeliveryList-Item:before {
        width: 18px;
        height: 15px; } }
    @media (min-width: 768px) {
      .DeliveryList-Item:before {
        top: 10px; } }

.DeliveryPartner {
  display: flex;
  flex-wrap: wrap; }
  .DeliveryPartner-Item {
    width: 50%;
    height: 89px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #D2D2D2;
    border-right: 1px solid #D2D2D2;
    display: flex;
    align-items: center; }
    .DeliveryPartner-Item:nth-child(1), .DeliveryPartner-Item:nth-child(2) {
      border-top: none; }
    .DeliveryPartner-Item:nth-child(2n) {
      justify-content: flex-end;
      border-right: none; }
  .DeliveryPartner-Img {
    display: block;
    max-width: 100%;
    max-height: 100%; }
    @media (min-width: 480px) {
      .DeliveryPartner-Img {
        width: initial !important; } }

.DeliveryPartner-Item:nth-child(1) .DeliveryPartner-Img {
  width: 141px; }

@media (min-width: 480px) {
  .DeliveryPartner-Item:nth-child(2) {
    justify-content: center; } }

.DeliveryPartner-Item:nth-child(2) .DeliveryPartner-Img {
  width: 116px; }

.DeliveryPartner-Item:nth-child(3) .DeliveryPartner-Img {
  width: 120px; }

.DeliveryPartner-Item:nth-child(4) .DeliveryPartner-Img {
  width: 112px; }

@media (min-width: 1600px) {
  .DeliveryMap {
    position: relative; } }

.DeliveryMap-Body {
  padding-top: 45px;
  padding-bottom: 40px; }
  @media (min-width: 992px) {
    .DeliveryMap-Body {
      padding-top: 70px;
      padding-bottom: 65px; } }

.DeliveryMap-Heading {
  margin-bottom: 15px;
  text-align: center; }
  @media (min-width: 768px) {
    .DeliveryMap-Heading {
      margin-bottom: 35px; } }

.DeliveryMap-Map {
  display: block;
  max-width: 100%;
  margin: 0 auto; }
  @media (min-width: 575px) {
    .DeliveryMap-Map_phone {
      display: none; } }
  .DeliveryMap-Map_desktop {
    display: none; }
    @media (min-width: 575px) {
      .DeliveryMap-Map_desktop {
        display: block; } }

.DeliveryMap-MinText {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px; }
  @media (min-width: 768px) {
    .DeliveryMap-MinText {
      font-size: 18px; } }
  .DeliveryMap-MinText-Icon {
    width: 13px;
    height: 11px;
    margin-right: 17px; }
    @media (min-width: 768px) {
      .DeliveryMap-MinText-Icon {
        width: 17px;
        height: 16px; } }

.DeliveryMap-Leaf {
  display: none; }
  @media (min-width: 1600px) {
    .DeliveryMap-Leaf {
      display: block; } }
  @media (min-width: 1600px) {
    .DeliveryMap-Leaf_left {
      position: absolute;
      top: -80px;
      left: 0; } }

@media (min-width: 992px) {
  .NeedHelp {
    background-image: url(/img/NeedHelp-Bg.png);
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 50%; } }
  @media (min-width: 992px) and (min-width: 1200px) {
    .NeedHelp {
      background-size: initial; } }

.NeedHelp-Body {
  padding-top: 35px;
  padding-bottom: 28px; }
  @media (min-width: 992px) {
    .NeedHelp-Body {
      padding-top: 65px;
      padding-bottom: 80px; } }

.NeedHelp-Content {
  max-width: 540px;
  margin: 0 auto; }
  @media (min-width: 992px) {
    .NeedHelp-Content {
      margin: 0; } }

.NeedHelp-Heading {
  margin-bottom: 10px;
  text-align: center; }
  @media (min-width: 992px) {
    .NeedHelp-Heading {
      text-align: left; } }

.NeedHelp-Subheading {
  margin-bottom: 30px;
  font-size: 14px;
  text-align: center; }
  @media (min-width: 992px) {
    .NeedHelp-Subheading {
      font-size: 18px;
      text-align: left; } }

.NeedHelpForm-Personal {
  font-size: 10px;
  text-align: center;
  color: #878787; }
  @media (min-width: 992px) {
    .NeedHelpForm-Personal {
      font-size: 14px;
      text-align: left; } }

.NeedHelpForm .Form-Btn {
  font-weight: 600; }
  @media (min-width: 992px) {
    .NeedHelpForm .Form-Btn {
      width: 255px;
      height: 50px;
      margin: 0;
      font-size: 15px; } }

.NeedHelpForm-Textarea {
  height: 88px; }

.StarRecommend {
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #F6F2F1; }
  @media (min-width: 992px) {
    .StarRecommend {
      padding-top: 65px;
      padding-bottom: 70px; } }
  .StarRecommend-Body {
    position: relative; }
  .StarRecommend-Heading {
    margin-bottom: 20px;
    text-align: center; }
    @media (min-width: 992px) {
      .StarRecommend-Heading {
        margin-bottom: 40px; } }
  .StarRecommend-Btn {
    width: 188px;
    height: 45px;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .StarRecommend-Btn {
        width: 255px;
        height: 55px;
        padding-bottom: 4px;
        font-size: 15px; } }

.StarRecommendSlider {
  width: 325px;
  height: 525px;
  margin: 0 auto;
  padding: 67px 62px 76px 70px;
  background-image: url(/img/SliderBg_iphone.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  left: -15px; }
  @media (min-width: 480px) {
    .StarRecommendSlider {
      left: 0; } }
  @media (min-width: 1200px) {
    .StarRecommendSlider {
      position: static;
      width: 364px;
      height: 593px;
      margin: 0;
      padding: 75px 69px 84px 78px; } }
  .StarRecommendSlider .slick-list {
    border-radius: 0 0 10px 10px;
    overflow: hidden; }
    @media (min-width: 1200px) {
      .StarRecommendSlider .slick-list {
        overflow: visible; } }
  .StarRecommendSlider-Slide {
    width: 100%;
    height: 100%;
    transform: scale(0.6);
    opacity: 0.5; }
    .StarRecommendSlider-Slide.slick-current {
      transform: none;
      opacity: 1; }
      .StarRecommendSlider-Slide.slick-current .StarRecommendSlider-Video:before {
        content: none; }
  .StarRecommendSlider-Video {
    width: 100%;
    height: 380px;
    position: relative; }
    .StarRecommendSlider-Video:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .StarRecommendSlider-Video iframe {
      display: block;
      width: 100%;
      height: 100%; }
  .StarRecommendSlider-SlideImg {
    max-width: 100%;
    max-height: 100%;
    display: block;
    object-fit: contain;
    border-radius: 0 0 18px 18px; }
  .StarRecommendSlider-Arrow {
    position: absolute;
    top: calc(50% - 19px);
    z-index: 100;
    width: 36px;
    height: 36px;
    transition: transform linear .20s;
    cursor: pointer; }
    .StarRecommendSlider-Arrow_prev {
      left: 0; }
      .StarRecommendSlider-Arrow_prev:hover {
        transform: scale(1.2); }
    .StarRecommendSlider-Arrow_next {
      right: 0;
      transform: rotate(180deg); }
      .StarRecommendSlider-Arrow_next:hover {
        transform: scale(1.2) rotate(180deg); }
    .StarRecommendSlider-Arrow-Icon {
      width: 100%; }
    .StarRecommendSlider-Arrow.slick-disabled {
      display: none !important; }

.ImPolitic {
  max-width: 600px;
  padding: 20px;
  font-weight: 300;
  background-color: #fff; }
  .ImPolitic-Title {
    font-size: 20px;
    font-weight: bold; }

.ImCallback {
  width: 100vw;
  min-height: 100vh;
  padding: 100px 0 30px;
  background-color: #fff; }
  @media (min-width: 768px) {
    .ImCallback {
      padding: 140px 0 40px; } }
  .ImCallback-Title {
    margin-bottom: 15px;
    font-size: 24px;
    text-align: center; }
    @media (min-width: 768px) {
      .ImCallback-Title {
        font-size: 32px; } }
  .ImCallback-Subtitle {
    margin-bottom: 50px;
    font-size: 14px;
    text-align: center; }
    @media (min-width: 768px) {
      .ImCallback-Subtitle {
        margin-bottom: 45px;
        font-size: 16px; } }
  .ImCallback-Input {
    margin-bottom: 10px; }
  @media (min-width: 768px) {
    .ImCallback-Btn {
      font-size: 15px; } }
  .ImCallback-ProcessingPersonalDataText {
    margin-top: 15px; }
    @media (min-width: 768px) {
      .ImCallback-ProcessingPersonalDataText {
        margin-top: 25px; } }

.ImCallbackForm-FormBg {
  margin-bottom: 40px;
  padding: 20px 0;
  background-color: #FBFBFB; }
  @media (min-width: 768px) {
    .ImCallbackForm-FormBg {
      margin-bottom: 60px;
      padding: 85px 0; } }

.ImCallbackForm .Container {
  position: relative; }

.ImCallbackForm-Leaf {
  position: absolute; }
  .ImCallbackForm-Leaf_left {
    display: none;
    width: 60px;
    left: 50%;
    bottom: -70px;
    transform: translateX(-50%) rotate(-45deg); }
    @media (min-width: 768px) {
      .ImCallbackForm-Leaf_left {
        display: block;
        width: 80px;
        left: -10px;
        bottom: -140px;
        transform: none; } }
  .ImCallbackForm-Leaf_right {
    display: none;
    width: 60px;
    top: -50px;
    right: -10px; }
    @media (min-width: 768px) {
      .ImCallbackForm-Leaf_right {
        display: block;
        width: 102px;
        top: -150px; } }

.ImCallbackForm-Heading {
  margin-bottom: 15px;
  font-size: 16px;
  text-align: center; }
  @media (min-width: 768px) {
    .ImCallbackForm-Heading {
      text-align: left; } }

.ImCallbackForm-ProductList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .ImCallbackForm-ProductList {
      display: block; } }
  .ImCallbackForm-ProductList-Item {
    margin-bottom: 15px; }
    .ImCallbackForm-ProductList-Item:last-child {
      margin-bottom: 0; }
  @media (min-width: 768px) {
    .ImCallbackForm-ProductList .Radio-Name br {
      display: none; } }

.ImCallbackForm-Textarea {
  height: 118px; }

.ImThanks {
  display: none;
  width: 100vw;
  height: 100vh;
  padding: 100px 0;
  background-color: #fff;
  text-align: center; }
  .ImThanks-Leaf {
    width: 102px; }
  .ImThanks-Title {
    margin-top: 30px;
    font-size: 32px;
    text-align: center; }
  .ImThanks-Subtitle {
    margin-top: 15px;
    font-size: 14px;
    color: #444444;
    text-align: center; }
  .ImThanks-SocialTitle {
    margin-top: 70px;
    font-size: 24px; }
  .ImThanks-Social {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none; }
    .ImThanks-Social-Img {
      position: relative;
      top: 3px;
      margin-right: 10px; }
    .ImThanks-Social .figcaption {
      font-size: 14px; }

.Footer {
  background-image: url(/img/Footer-Bg.jpg);
  background-position: center center; }
  .Footer-Body {
    position: relative;
    padding-top: 35px;
    padding-bottom: 35px;
    color: #FFFFFF; }
    @media (min-width: 992px) {
      .Footer-Body {
        display: flex;
        justify-content: space-between;
        padding-top: 40px;
        padding-bottom: 70px; } }
  .Footer-Left {
    margin-bottom: 30px;
    text-align: center; }
    @media (min-width: 992px) {
      .Footer-Left {
        margin-bottom: 0;
        text-align: left;
        order: 3; } }
  .Footer-Center {
    margin-bottom: 30px;
    text-align: center; }
    @media (min-width: 992px) {
      .Footer-Center {
        margin-bottom: 0;
        text-align: left;
        order: 2; } }
  .Footer-Right {
    margin-bottom: 17px;
    text-align: center; }
    @media (min-width: 992px) {
      .Footer-Right {
        margin-bottom: 0;
        text-align: left;
        order: 1; } }
  .Footer-Phone {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 300; }
    @media (min-width: 992px) {
      .Footer-Phone {
        font-size: 18px; } }
    .Footer-Phone a {
      font-weight: 700;
      text-decoration: none; }
  .Footer-WorkText {
    margin-bottom: 30px;
    font-size: 13px;
    font-weight: 300; }
    @media (min-width: 992px) {
      .Footer-WorkText {
        margin-bottom: 20px;
        font-size: 18px; } }
  .Footer-Social {
    max-width: 290px;
    margin: 0 auto; }
    @media (min-width: 992px) {
      .Footer-Social {
        max-width: 170px;
        margin: 0; } }
  .Footer-Logo {
    width: 173px;
    margin-bottom: 15px; }
  .Footer-Copyright {
    font-size: 10px;
    font-weight: 300; }
    @media (min-width: 992px) {
      .Footer-Copyright {
        padding-top: 17px;
        font-size: 13px;
        color: #b3b3b3;
        border-top: 1px solid #fff; } }
    .Footer-Copyright br {
      display: none; }
      @media (min-width: 992px) {
        .Footer-Copyright br {
          display: block; } }
  .Footer-ArrowToTop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    margin: 0 auto;
    padding-bottom: 3px;
    background-color: #FBBD00;
    border-radius: 50%;
    cursor: pointer; }
    @media (min-width: 768px) {
      .Footer-ArrowToTop {
        position: fixed;
        right: 10px;
        bottom: 10px;
        z-index: 1000; } }
    @media (min-width: 1600px) {
      .Footer-ArrowToTop {
        top: 70px;
        right: auto;
        left: calc(100% + 100px);
        bottom: auto; } }
    .Footer-ArrowToTop-Icon {
      width: 18px; }

.FooterSocial {
  display: flex;
  align-items: center;
  justify-content: space-around; }
  .FooterSocial-Item {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%; }
    @media (min-width: 992px) {
      .FooterSocial-Item {
        width: 34px;
        height: 34px; } }
    .FooterSocial-Item a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
  .FooterSocial-Icon {
    display: block; }

.FooterLinkList-Link {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 300; }
  @media (min-width: 992px) {
    .FooterLinkList-Link {
      margin-bottom: 15px;
      font-size: 15px; } }
