.GiveChance {
  &-Body {
    @include mf(sx) {
      padding-top: 50px;
      position: relative; } }
  &-SpecImgWrap {
    width: 320px;
    margin: 0 auto 10px;
    @include mf(sx) {
      position: absolute;
      left: 350px;
      bottom: 60px; }
    @include mf(sm) {
      width: 420px;
      left: 550px; }
    @include mf(sl) {
      left: 600px;
      bottom: 20px; }
    @include mf(lg) {
      width: 641px;
      right: -260px;
      bottom: 0; } }
  &-SpecImg {
    width: 100%;
    display: block; }
  &-Content {
    @include mf(sx) {
      max-width: 355px;
      position: relative;
      z-index: 2; }
    @include mf(sm) {
      max-width: 760px; } }
  &-Heading {
    margin-bottom: 10px;
    text-align: center;
    @include mf(sx) {
      text-align: left; }
    @include mf(sm) {
      margin-bottom: 30px; }
    br {
      @include mf(sx) {
        display: none; } } }
  &-Subheading {
    margin-bottom: 40px;
    text-align: center;
    @include mf(sx) {
      text-align: left; }
    @include mf(sm) {
      margin-bottom: 60px;
      font-size: 18px; } }
  &-Partners {
    @include mf(sm) {
      max-width: 555px; } } }

.GiveChancePartners {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &-Item {
    width: 33.33%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    @include mf(sm) {
      margin-bottom: 35px; }
    @include mf(sl) {
      width: 25%; }
    &:nth-child(1) {
      @include mf(sl) {
        order: 2; }
      .GiveChancePartners-Img {
        width: 58px; } }
    &:nth-child(2) {
      @include mf(sl) {
        order: 7; }
      .GiveChancePartners-Img {
        width: 88px; } }
    &:nth-child(3) {
      @include mf(sl) {
        order: 10; }
      .GiveChancePartners-Img {
        width: 79px; } }
    &:nth-child(4) {
      @include mf(sl) {
        order: 9; }
      .GiveChancePartners-Img {
        width: 58px; } }
    &:nth-child(5) {
      @include mf(sl) {
        order: 6; }
      .GiveChancePartners-Img {
        width: 90px; } }
    &:nth-child(6) {
      width: 17.16%;
      @include mf(sl) {
        width: 44px;
        order: 4; }
      .GiveChancePartners-Img {
        width: 31px; } }
    &:nth-child(7) {
      width: 16.16%;
      @include mf(sl) {
        width: 44px;
        order: 8; }
      .GiveChancePartners-Img {
        width: 30px; } }
    &:nth-child(8) {
      @include mf(sl) {
        order: 1; }
      .GiveChancePartners-Img {
        width: 58px; } }
    &:nth-child(9) {
      @include mf(sl) {
        order: 5; }
      .GiveChancePartners-Img {
        width: 86px; } }
    &:nth-child(10) {
      @include mf(sl) {
        order: 3; }
      .GiveChancePartners-Img {
        width: 80px; } }
    &:nth-child(11) {
      @include mf(sl) {
        order: 11; }
      .GiveChancePartners-Img {
        width: 90px; } }
    &:nth-child(12) {
      @include mf(sl) {
        width: 40px;
        order: 12; } } }
  &-Img {
    display: block;
    @include mf(sm) {
      width: auto !important; } } }
