.AfterUse {
  &-Body {
    padding-top: 50px;
    padding-bottom: 65px;
    @include mf(sl) {
      display: flex; }
    @include mf(lg) {
      padding-top: 100px;
      padding-bottom: 130px; } }
  &-Left {
    margin-bottom: 55px;
    @include mf(lg) {
      width: calc(100% - 526px);
      padding-right: 60px; } }
  &-Right {
    width: 100%;
    @include mf(sl) {
      padding-top: 50px; }
    @include mf(lg) {
      width: 526px;
      padding-top: 20px; } }
  &-Heading {
    margin-bottom: 20px;
    text-align: center;
    @include mf(sm) {
      margin-bottom: 35px; }
    @include mf(sl) {
      line-height: 1.2;
      text-align: left; } }
  &-List {}
  &-SpecImg {
    display: block;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    @include mf(sl) {
      max-width: 526px; } } }

.AfterUseList {
  &-Item {
    position: relative;
    margin-bottom: 15px;
    padding-left: 24px;
    @include mf(sm) {
      margin-bottom: 30px;
      padding-left: 60px;
      font-size: 22px; }
    &:last-child {
      margin-bottom: 0; }
    &:before {
      content: '';
      width: 13px;
      height: 11px;
      background-image: url(/img/check.png);
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 2px;
      left: 0;
      @include mf(xs) {
        width: 18px;
        height: 15px; }
      @include mf(sm) {
        width: 25px;
        height: 22px; } } } }
