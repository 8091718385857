.StarRecommend {
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #F6F2F1;
  @include mf(sl) {
    padding-top: 65px;
    padding-bottom: 70px; }
  &-Body {
    position: relative; }
  &-Heading {
    margin-bottom: 20px;
    text-align: center;
    @include mf(sl) {
      margin-bottom: 40px; } }
  &-Slider {}
  &-Btn {
    width: 188px;
    height: 45px;
    margin: 0 auto;
    @include mf(sm) {
      width: 255px;
      height: 55px;
      padding-bottom: 4px;
      font-size: 15px; } } }


.StarRecommendSlider {
  width: 325px;
  height: 525px;
  margin: 0 auto;
  padding: 67px 62px 76px 70px;
  background-image: url(/img/SliderBg_iphone.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  left: -15px;
  @include mf(xs) {
    left: 0; }
  @include mf(lg) {
    position: static;
    width: 364px;
    height: 593px;
    margin: 0;
    padding: 75px 69px 84px 78px; }
  .slick-list {
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    @include mf(lg) {
      overflow: visible; } }
  &-Slide {
    width: 100%;
    height: 100%;
    transform: scale(0.6);
    opacity: 0.5;
    &.slick-current {
      transform: none;
      opacity: 1;
      .StarRecommendSlider-Video {
        &:before {
          content: none; } } } }
  &-Video {
    width: 100%;
    height: 380px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    iframe {
      display: block;
      width: 100%;
      height: 100%; } }
  &-SlideImg {
    max-width: 100%;
    max-height: 100%;
    display: block;
    object-fit: contain;
    border-radius: 0 0 18px 18px; }
  &-Arrow {
    position: absolute;
    top: calc(50% - 19px);
    z-index: 100;
    width: 36px;
    height: 36px;
    transition: transform linear .20s;
    cursor: pointer;
    &_prev {
      left: 0;
      &:hover {
        transform: scale(1.2); } }
    &_next {
      right: 0;
      transform: rotate(180deg);
      &:hover {
        transform: scale(1.2) rotate(180deg); } }
    &-Icon {
      width: 100%; }
    &.slick-disabled {
      display: none !important; } } }
//+mf(lg)
//  .StarRecommendSlider
//    width: 100%
//    height: initial
//    margin: 0
//    padding: initial
//    background-image: initial
//    background-repeat: initial
//    background-size: initial
//    position: static
//    left: 0
//    .slick-list
//    &-Slide
//      width: 100%
//      height: 100%
//      opacity: 0.5
//    .slick-current
//      position: relative
//      left: -15px
//      width: 364px
//      height: 593px
//      margin: 0 auto
//      padding: 75px 69px 84px 78px
//      background-image: url(/img/SliderBg_iphone.png)
//      background-repeat: no-repeat
//      background-size: 100% 100%
//      overflow: hidden
//      opacity: 1
//    &-SlideImg
//      display: block
//      max-width: 100%
//      max-height: 100%
//      border-radius: 0 0 18px 18px
//      object-fit: contain
//    &-Arrow
//      width: 36px
//      height: 36px
//      position: absolute
//      top: calc(50% - 19px)
//      transition: transform linear .20s
//      cursor: pointer
//      &_prev
//        left: 0
//        &:hover
//          transform: scale(1.2)
//      &_next
//        right: 0
//        transform: rotate(180deg)
//        &:hover
//          transform: scale(1.2) rotate(180deg)
//      &-Icon
//        width: 100%
