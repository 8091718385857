.WhyWe {
  background-color: #F6F2F1;
  @include mf(lg) {
    background-image: url(/img/WhyWe-Bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
  &-Body {
    position: relative;
    padding-top: 35px;
    padding-bottom: 45px;
    @include mf(sl) {
      padding-top: 50px;
      padding-bottom: 60px; } }
  &-List {}
  &-Leaf {
    position: absolute;
    &_left {
      width: 102px;
      top: -50px;
      right: 25px;
      @include mf(lg) {
        width: auto;
        top: -105px;
        left: auto;
        right: calc(100% + 60px); } } } }

.WhyWeList {
  @include mf(sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  &-Item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @include mf(sm) {
      width: 50%; }
    @include mf(sl) {
      display: block;
      width: auto;
      margin: 0;
      text-align: center; }
    &:last-child {
      margin-bottom: 0; }
    br {
      display: none;
      @include mf(sm) {
        display: block; } } }
  &-IconWrap {
    width: 68px;
    height: 68px;
    @include mf(sl) {
      width: 101px;
      height: 101px;
      margin: 0 auto 15px; } }
  &-Icon {
    display: block;
    width: 100%;
    height: 100%; }
  &-Text {
    width: calc(100% - 68px);
    padding-left: 25px;
    @include mf(sl) {
      width: auto;
      padding-left: 0; } } }
